export default defineI18nConfig(() => ({
  legacy: false,
  locale: "en",
  messages: {
    en: {
      first_name: "First name",
      last_name: "Last name",
      phone_number: "Phone number",
      logo: "Logo",
      personal_photo: "Personal photo",
      personal_message: "Personal message",
      call_to_action_button: "Button with hyperlink",
      button_text: "Button text",
      none: "None",
      preset: "Preset",
      custom: "Custom",
      preview: "Preview",
      share: "Share",
      personalize: "Personalize",
      view: "View",
      complete_your_profile: "Complete Your Profile",
      personal_details: "Personal Details",
      customize_content_experience: "Customize Content Experience",
      share_on_whatsapp: "Share on WhatsApp",
      language: "Language",
      ai_description: "Our AI Assistant is writing something just for you! Simply copy, paste, edit and share.",
      ai_regenerate: "Try something different",
      copy_text_create_post: "Copy & paste on Facebook",
      copy_link_to_content_experience: "Copy link to your content experience",
      email: "Email",
      qr_code: "QR Code",
      link: "Link",
      my_experiences: "My Experiences",
      my_profile: "My Profile",
      about_my_business: "About My Business",
      i_am_a_travel_advisor: "I am a travel advisor/agency or similar third-party seller of travel",
      host_agency: "Host Agency",
      agency_association_consortium: "Agency Association / Consortium",
      booking_email: "Booking Email",
      booking_phone_number: "Booking Phone Number",
      iata_used_for_booking: "IATA used for booking",
      clia_used_for_booking: "CLIA used for booking",
      company_name: "Company Name",
      business_languages: "Languages I conduct business in",
      update: "Update",
      saving: "Saving",
      saved: "Saved",
      logout: "Logout",
      cobrand_and_share: "Co-brand & share",
      your_content_experiences: "Your Content Experiences",
      your_experiences: "Your Experiences",
      new_experiences: "New Experiences",
      all_content_experiences: "All Content Experiences",
      search: "Search",
      experiences: "Experiences",
      cobranded_website: "Co-branded website",
      about: "About",
      your_partnerName_content_experiences: "Your :partnerName Content Experiences",
      content_experiences_description:
        "Content experiences enable you to quickly deliver inspirational content in a highly personalized way. **Click an experience below to get started.**",
      cobrand_the_partnerName_website: "Co-brand the :partnerName website",
      cobrand_website_url_description:
        "Here's your personal, co-branded :partnerName website. Safely share this link with clients - we've transformed the site just for you.",
      copy_link: "Copy Link",
      copied: "Copied",
      cobrand_website_any_url_description:
        "It gets even better! You can co-brand any page on the :partnerName website. Simply enter any :partnerName URL to get your co-branded link.",
      cobrand: "Co-brand",
      contact: "Contact",
      for_travel_brands: "For travel brands",
      recommend: "Recommend",
      recommend_us_to_suppliers: "Recommend us to your favorite partners",
      recommend_us_description:
        "Don't you wish all of your partners made marketing this easy? Let them know how they can easily create content that elevates your marketing. Click the button and let us craft an email for you!",
      cancel: "Cancel",
      copy_and_paste_in_email: "Copy (and paste into an email)",
      pagination: ":itemsFrom - :itemsTo of :itemsTotal items.",
      choose_a_content_experience: "Choose a content experience",
      sign_in: "Sign In",
      get_started: "Get Started",
      sign_in_to_account: "Sign in to your account",
      sign_in_description: "Enter your email and we'll send a link that logs you in automatically.",
      email_address: "Email address",
      no_account: "Don't have an account?",
      sign_up_title: "Sign up to start sharing co-branded experiences",
      sign_up_description: "In a matter of seconds, you can co-brand & share our content experiences to grow your business.",
      create_account: "Create account",
      personalize_your_content_experience: "Personalize this content experience",
      invitation_description:
        "We've crafted this content experience to boost your marketing and drive conversion. It's easy to personalize—simply enter your email to get started!",
      enter_email_legal:
        "By entering your email address, you agree to the [terms of service](https://approachguides.com/terms/) and [privacy policy](https://approachguides.com/privacy/)",
      and: "and",
      magic_link_email_sent_title: "Check your email!",
      magic_link_email_sent_description: "Click the magic link we sent to **:userEmail**",
      magic_link_legal:
        "By clicking the magic link, you agree to the [terms of service](https://approachguides.com/terms/) and [privacy policy](https://approachguides.com/privacy/)",
      preview_description: "Tip: Use an incognito browser for your clients' view.",
      name: "Name",
      logo_personal_photo: "Logo & Personal Photo",
      location: "Location",
      consortium: "Consortium",
      booking_email_phone: "Booking Email & Phone",
      home: "Home",
      update_profile: "Update Profile",
      profile_instructions: "Please confirm or fill in the highlighted fields.",
      select: "Select",
      country: "Country",
      state_region: "State / Region",
      homepage_title: "Supercharge your marketing & sales efforts",
      homepage_subtitle: "with free personalized content experiences",
      share_qr_code_description: "Download the code or screenshot it for even faster sharing.",
      download_qr_code: "Download QR code",
      bilbert_email_start: "I'm crafting an email, personally for you! Give me a few seconds",
      bilbert_email_end: "All done! Simply click the button below to copy, and then paste it into an email and send!",
      ai_robot: "AI Assistant",
      sign_up: "Sign Up",
      already_signed_up: "Already signed up?",
      cookies_description: "We use cookies to improve your experience.",
      learn_more: "Learn More",
      accept: "Accept",
      hooray: "Hooray!",
      experience_creation_description: "Give us a moment while we create your co-branded experience...",
      share_link_everywhere: "Share your link everywhere",
      sales_links_header: "Links to share with partners",
      sales_links_description:
        "Attending a meeting or event, doing a webinar, sending out an email? This is a complete, up-to-date resource with everything you need to invite partners to co-brand & share your content experiences.",
      step_one_header: "Copy the invitation link or download the QR code",
      step_one_description:
        "Copy the invitation link or download the QR code for either all :partnerName content experiences, or choose a specific content experience.",
      step_one_tip: "don't forget to **test** your QR code before sharing!",
      copy_invite_link: "Copy invite link",
      copied_to_clipboard: "Copied to clipboard",
      or_choose_content_experience: "Or choose a specific content experience:",
      step_two_header: "Put it into your webinar presentation, email, and social posts",
      step_two_description: "Put it into your webinar presentation, email, social post... wherever you are engaging with trade partners.",
      step_three_header: "Add explanatory copy",
      step_three_description: "Add explanatory copy to help partners understand why you are sharing the links and QR codes.",
      step_three_tip: "don't forget to paste in your link and/or QR code!",
      sales_example_one: "It's the easiest way to sell :partnerName. Click to get started!",
      sales_example_two: "Click to instantly co-brand & share our latest content experience.",
      sales_example_three: "Click to instantly begin selling :partnerName",
      just_a_moment: "Just a moment",
      thats_strange: "That's strange...",
      invalid_magic_link: "This magic link is invalid. Please re-click the magic link button or paste in the associated magic URL",
      oops: "Oops!",
      magic_link_used: "Magic links are for one-time use and this one has already been used. Please re-enter your email address to receive a new magic link.",
      get_new_magic_link: "Get your new magic link!",
      new_magic_link_sent: "Your magic link expired so we have sent you a new one.",
      magic_link_check_email: "Check your email and click to login",
      cobrand_our_website: "Co-brand our website",
      required_field: "This field is required.",
      sales_example_description: "Here are some examples to get you started:",
      confirm: "Confirm",
      your: "Your",
      you_email_com: "your",
      upload_image: "Upload image...",
      demo_phone_number: "+1 212 555 1212",
    },
    enfortranslation: {
      first_name: "First name",
      last_name: "Last name",
      phone_number: "Phone number",
      logo: "Logo",
      personal_photo: "Personal photo",
      personal_message: "Personal message",
      call_to_action_button: "Button with hyperlink",
      button_text: "Button text",
      none: "None",
      preset: "Preset",
      custom: "Custom",
      preview: "Preview",
      share: "Share",
      personalize: "Personalize",
      view: "View",
      complete_your_profile: "Complete Your Profile",
      personal_details: "Contact Information",
      customize_content_experience: "Personalze Content Experience",
      share_on_whatsapp: "Share on WhatsApp",
      language: "Language",
      ai_description: "Our AI Assistant is writing something just for you! Simply copy, paste, edit and share.",
      ai_regenerate: "Try something different",
      copy_text_create_post: "Copy & paste on Facebook",
      copy_link_to_content_experience: "Copy link to your content experience",
      email: "Email",
      qr_code: "QR Code",
      link: "Link",
      my_experiences: "My Experiences",
      my_profile: "My Profile",
      about_my_business: "About My Company",
      i_am_a_travel_advisor: "I am a travel advisor/agency or similar third-party seller of travel",
      host_agency: "Host Agency",
      agency_association_consortium: "Agency Association / Consortium",
      booking_email: "Email used for booking",
      booking_phone_number: "Phone number used for booking",
      iata_used_for_booking: "IATA used for booking",
      clia_used_for_booking: "CLIA used for booking",
      company_name: "Company Name",
      business_languages: "Languages I conduct business in",
      update: "Update",
      saving: "Saving",
      saved: "Saved",
      logout: "Logout",
      cobrand_and_share: "Personalize & share",
      your_content_experiences: "Your Content Experiences",
      your_experiences: "Your Experiences",
      new_experiences: "New Experiences",
      all_content_experiences: "All Content Experiences",
      search: "Search",
      experiences: "Experiences",
      cobranded_website: "Personalized website",
      about: "About",
      your_partnerName_content_experiences: "Your :partnerName Content Experiences",
      content_experiences_description:
        "Content experiences enable you to quickly share inspirational content in a highly personalized way. **Click on a content experience below to get started.**",
      cobrand_the_partnerName_website: "Personalize the :partnerName website",
      cobrand_website_url_description: "Here's your personalized :partnerName website. Share this link with clients - we've transformed the site just for you.",
      copy_link: "Copy Link",
      copied: "Copied",
      cobrand_website_any_url_description:
        "It gets even better! You can personalize any page on the :partnerName website. Simply enter any :partnerName URL to get your personalized link.",
      cobrand: "Personalize",
      contact: "Contact",
      for_travel_brands: "For travel brands",
      recommend: "Recommend",
      recommend_us_to_suppliers: "Recommend us to your favorite partners",
      recommend_us_description:
        "Don't you wish all of your partners made marketing this easy? Let them know how they can easily create content that elevates your marketing. Click the button and let us craft an email for you!",
      cancel: "Cancel",
      copy_and_paste_in_email: "Copy (and paste into an email)",
      pagination: ":itemsFrom - :itemsTo of :itemsTotal items.",
      choose_a_content_experience: "Choose a content experience",
      sign_in: "Sign In",
      get_started: "Get Started",
      sign_in_to_account: "Sign in to your account",
      sign_in_description: "Enter your email and we'll send a link that logs you in automatically.",
      email_address: "Email address",
      no_account: "Don't have an account?",
      sign_up_title: "Sign up to start sharing personalized content",
      sign_up_description: "In a matter of seconds, you can personalize & share our content experiences to take your marketing to the next level.",
      create_account: "Create account",
      personalize_your_content_experience: "Personalize this content experience",
      invitation_description:
        "We've crafted this content experience to elevate your marketing and help you earn more. It's easy to personalize—simply enter your email to get started!",
      enter_email_legal:
        "By entering your email address, you agree to the [terms of service](https://approachguides.com/terms/) and [privacy policy](https://approachguides.com/privacy/)",
      and: "and",
      magic_link_email_sent_title: "Check your email!",
      magic_link_email_sent_description: "Click the magic link we sent to **:userEmail**",
      magic_link_legal:
        "By clicking the magic link, you agree to the [terms of service](https://approachguides.com/terms/) and [privacy policy](https://approachguides.com/privacy/)",
      preview_description: "Tip: Use an incognito browser for your clients' view.",
      name: "Name",
      logo_personal_photo: "Logo & Personal Photo",
      location: "Location",
      consortium: "Consortium",
      booking_email_phone: "Booking Email & Phone",
      home: "Home",
      update_profile: "Update Profile",
      profile_instructions: "Please confirm or fill in the highlighted fields.",
      select: "Select",
      country: "Country",
      state_region: "State / Region",
      homepage_title: "Take your marketing & sales to the next level",
      homepage_subtitle: "with free personalized content experiences",
      share_qr_code_description: "Download the QR code or screenshot it for fast sharing.",
      download_qr_code: "Download QR code",
      bilbert_email_start: "I'm crafting an email just for you! Give me a few seconds",
      bilbert_email_end: "All done! Click the button below to copy the text, then paste it into an email and send!",
      ai_robot: "AI Assistant",
      sign_up: "Sign Up",
      already_signed_up: "Already signed up?",
      cookies_description: "We use cookies to improve your experience.",
      learn_more: "Learn More",
      accept: "Accept",
      hooray: "Hooray!",
      experience_creation_description: "Give us a moment while we create your personalized experience...",
      share_link_everywhere: "Share your link everywhere",
      sales_links_header: "Links to share with partners",
      sales_links_description:
        "Attending a meeting or event, doing a webinar, sending out an email? This is everything you need to invite partners to personalize & share your content experiences.",
      step_one_header: "Copy the invitation link or download the QR code",
      step_one_description:
        "Copy the invitation link or download the QR code for either all :partnerName content experiences, or choose a specific content experience.",
      step_one_tip: "don't forget to **test** your QR code before sharing!",
      copy_invite_link: "Copy invite link",
      copied_to_clipboard: "Copied to clipboard",
      or_choose_content_experience: "Or choose a specific content experience:",
      step_two_header: "Put it into your webinar presentation, email, and social posts",
      step_two_description: "Put it into your webinar presentation, email, social post... wherever you are engaging with trade partners.",
      step_three_header: "Add explanatory copy",
      step_three_description: "Add explanatory copy to help partners understand why you are sharing the links and QR codes.",
      step_three_tip: "don't forget to paste in your link and/or QR code!",
      sales_example_one: "It's the easiest way to sell :partnerName. Click to get started!",
      sales_example_two: "Click to instantly personalize & share our latest content experience.",
      sales_example_three: "Click to instantly begin selling :partnerName",
      just_a_moment: "Just a moment",
      thats_strange: "That's strange...",
      invalid_magic_link: "This magic link is invalid. Please re-click the magic link button or paste in the URL",
      oops: "Oops!",
      magic_link_used: "Magic links are for one-time use and this one has already been used. Please re-enter your email address to receive a new magic link.",
      get_new_magic_link: "Get your new magic link!",
      new_magic_link_sent: "Your magic link expired so we have sent you a new one.",
      magic_link_check_email: "Check your email and click to login",
      cobrand_our_website: "Personalize our website",
      required_field: "This field is required.",
      sales_example_description: "Here are some examples to get you started:",
      confirm: "Confirm",
      your: "Your",
      you_email_com: "you",
      upload_image: "Upload image...",
      demo_phone_number: "+1 212 555 1212",
    },
    it: {
      first_name: "Nome",
      last_name: "Cognome",
      phone_number: "Numero di telefono",
      logo: "Logo",
      personal_photo: "Foto personale",
      personal_message: "Messaggio personale",
      call_to_action_button: "Pulsante con link",
      button_text: "Testo del pulsante",
      none: "Nessuno",
      preset: "Predefinito",
      custom: "Personalizzato",
      preview: "Anteprima",
      share: "Condividi",
      personalize: "Personalizza",
      view: "Visualizza",
      complete_your_profile: "Completa il tuo profilo",
      personal_details: "Informazioni di contatto",
      customize_content_experience: "Personalizza esperienza di contenuto",
      share_on_whatsapp: "Condividi su WhatsApp",
      language: "Lingua",
      ai_description: "Il nostro assistente AI sta scrivendo qualcosa solo per te! Copia, incolla, modifica e condividi.",
      ai_regenerate: "Prova qualcosa di diverso",
      copy_text_create_post: "Copia e incolla su Facebook",
      copy_link_to_content_experience: "Copia link alla tua esperienza di contenuto",
      email: "E-mail",
      qr_code: "Codice QR",
      link: "Link",
      my_experiences: "Le mie esperienze",
      my_profile: "Il mio profilo",
      about_my_business: "Informazioni sulla mia attività",
      i_am_a_travel_advisor: "Sono un consulente di viaggio/agenzia o un venditore di viaggi di terze parti simile",
      host_agency: "Agenzia ospitante",
      agency_association_consortium: "Associazione di agenzie / Consorzio",
      booking_email: "E-mail utilizzata per le prenotazioni",
      booking_phone_number: "Numero di telefono utilizzato per le prenotazioni",
      iata_used_for_booking: "IATA usato per le prenotazioni",
      clia_used_for_booking: "CLIA usato per le prenotazioni",
      company_name: "Nome azienda",
      business_languages: "Lingue in cui svolgo affari",
      update: "Aggiorna",
      saving: "Salvataggio",
      saved: "Salvato",
      logout: "Esci",
      cobrand_and_share: "Personalizza e condividi",
      your_content_experiences: "Le tue esperienze di contenuto",
      your_experiences: "Le tue esperienze",
      new_experiences: "Nuove esperienze",
      all_content_experiences: "Tutte le esperienze di contenuto",
      search: "Cerca",
      experiences: "Esperienze",
      cobranded_website: "Sito web personalizzato",
      about: "Chi siamo",
      your_partnerName_content_experiences: "Le tue esperienze di contenuto :partnerName",
      content_experiences_description:
        "Le esperienze di contenuto ti permettono di condividere rapidamente contenuti ispiratori in modo altamente personalizzato. **Clicca su un'esperienza di contenuto qui sotto per iniziare.**",
      cobrand_the_partnerName_website: "Personalizza il sito web di :partnerName",
      cobrand_website_url_description:
        "Ecco il tuo sito web personalizzato :partnerName. Condividi questo link con i clienti - abbiamo trasformato il sito solo per te.",
      copy_link: "Copia link",
      copied: "Copiato",
      cobrand_website_any_url_description:
        "Diventa ancora meglio! Puoi personalizzare qualsiasi pagina del sito web di :partnerName. Basta inserire un URL di :partnerName per ottenere il tuo link personalizzato.",
      cobrand: "Personalizza",
      contact: "Contatto",
      for_travel_brands: "Per marchi di viaggi",
      recommend: "Raccomanda",
      recommend_us_to_suppliers: "Raccomandaci ai tuoi fornitori preferiti",
      recommend_us_description:
        "Non sarebbe bello se tutti i tuoi fornitori rendessero il marketing così facile? Fai sapere loro che possono creare contenuti per migliorare il tuo marketing. Clicca sul pulsante e lascia che creiamo una e-mail per te!",
      cancel: "Annulla",
      copy_and_paste_in_email: "Copia (e incolla in una e-mail)",
      pagination: ":itemsFrom - :itemsTo di :itemsTotal articoli.",
      choose_a_content_experience: "Scegli un'esperienza di contenuto",
      sign_in: "Accedi",
      get_started: "Inizia",
      sign_in_to_account: "Accedi al tuo account",
      sign_in_description: "Inserisci la tua e-mail e ti invieremo un link che ti farà accedere automaticamente.",
      email_address: "Indirizzo e-mail",
      no_account: "Non hai un account?",
      sign_up_title: "Iscriviti per iniziare a condividere contenuti personalizzati",
      sign_up_description: "In pochi secondi, puoi personalizzare e condividere le nostre esperienze di contenuto per far crescere la tua attività.",
      create_account: "Crea account",
      personalize_your_content_experience: "Personalizza questa esperienza di contenuto",
      invitation_description:
        "Abbiamo creato questa esperienza di contenuto per migliorare il tuo marketing e aiutarti a guadagnare di più. È facile personalizzare—basta inserire la tua e-mail per iniziare!",
      enter_email_legal:
        "Inserendo il tuo indirizzo email, accetti i [termini di servizio](https://approachguides.com/terms/) e l'[informativa sulla privacy](https://approachguides.com/privacy/)",
      and: "e",
      magic_link_email_sent_title: "Controlla la tua e-mail!",
      magic_link_email_sent_description: "Clicca sul link magico che ti abbiamo inviato a **:userEmail**",
      magic_link_legal:
        "Cliccando sul link magico, accetti i [termini di servizio](https://approachguides.com/terms/) e l'[informativa sulla privacy](https://approachguides.com/privacy/)",
      preview_description: "Suggerimento: usa un browser in modalità incognito per visualizzare ciò che vedono i tuoi clienti.",
      name: "Nome",
      logo_personal_photo: "Logo e foto personale",
      location: "Posizione",
      consortium: "Consorzio",
      booking_email_phone: "E-mail di prenotazione e telefono",
      home: "Home",
      update_profile: "Aggiorna profilo",
      profile_instructions: "Conferma o compila i campi evidenziati.",
      select: "Seleziona",
      country: "Paese",
      state_region: "Stato / Regione",
      homepage_title: "Porta il tuo marketing e le tue vendite al prossimo livello",
      homepage_subtitle: "con esperienze di contenuto personalizzate gratuite",
      share_qr_code_description: "Scarica il codice QR o fai uno screenshot per condividerlo più velocemente.",
      download_qr_code: "Scarica codice QR",
      bilbert_email_start: "Sto creando un'e-mail, personalmente per te! Dammi qualche secondo",
      bilbert_email_end: "Fatto! Clicca sul pulsante qui sotto per copiare il testo, quindi incollalo in una e-mail e invia!",
      ai_robot: "Assistente AI",
      sign_up: "Iscriviti",
      already_signed_up: "Già iscritto?",
      cookies_description: "Usiamo i cookie per migliorare la tua esperienza.",
      learn_more: "Scopri di più",
      accept: "Accetta",
      hooray: "Eviva!",
      experience_creation_description: "Dammi un momento mentre creiamo la tua esperienza personalizzata...",
      share_link_everywhere: "Condividi il tuo link ovunque",
      sales_links_header: "Link da condividere con i partner",
      sales_links_description:
        "Partecipa a una riunione o evento, fai un webinar, invia un'email? Questa è una risorsa completa e aggiornata con tutto ciò di cui hai bisogno per invitare i partner a personalizzare e condividere le tue esperienze di contenuto.",
      step_one_header: "Copia il link di invito o scarica il codice QR",
      step_one_description:
        "Copia il link di invito o scarica il codice QR per tutte le esperienze di contenuto :partnerName, o scegli un'esperienza di contenuto specifica.",
      step_one_tip: "Non dimenticare di **testare** il tuo codice QR prima di condividerlo!",
      copy_invite_link: "Copia link di invito",
      copied_to_clipboard: "Copiato negli appunti",
      or_choose_content_experience: "O scegli un'esperienza di contenuto specifica:",
      step_two_header: "Mettilo nella tua presentazione webinar, email e post sui social",
      step_two_description: "Mettilo nella tua presentazione webinar, email, post sui social... ovunque interagisci con partner commerciali.",
      step_three_header: "Aggiungi testo esplicativo",
      step_three_description: "Aggiungi testo esplicativo per aiutare i partner a capire perché stai condividendo i link e i codici QR.",
      step_three_tip: "Non dimenticare di incollare il tuo link e/o codice QR!",
      sales_example_one: "È il modo più semplice per vendere :partnerName. Clicca per iniziare!",
      sales_example_two: "Clicca per personalizzare e condividere istantaneamente la nostra ultima esperienza di contenuto.",
      sales_example_three: "Clicca per iniziare a vendere :partnerName subito",
      just_a_moment: "Un momento",
      thats_strange: "È strano...",
      invalid_magic_link: "Questo link magico non è valido. Clicca di nuovo sul pulsante del link magico o incolla l'URL associato",
      oops: "Ops!",
      magic_link_used:
        "I link magici sono per uso una sola volta e questo è già stato utilizzato. Inserisci di nuovo il tuo indirizzo email per ricevere un nuovo link magico.",
      get_new_magic_link: "Ottieni il tuo nuovo link magico!",
      new_magic_link_sent: "Il tuo link magico è scaduto, quindi ti abbiamo inviato uno nuovo.",
      magic_link_check_email: "Controlla la tua email e clicca per accedere",
      cobrand_our_website: "Personalizza il nostro sito web",
      required_field: "Questo campo è obbligatorio.",
      sales_example_description: "Ecco alcuni esempi per iniziare:",
      confirm: "Conferma",
      your: "Il Tuo",
      you_email_com: "tu",
      upload_image: "Carica immagine...",
      demo_phone_number: "+39 041 555 1212",
    },
    es: {
      first_name: "Nombre",
      last_name: "Apellido",
      phone_number: "Número de teléfono",
      logo: "Logo",
      personal_photo: "Foto personal",
      personal_message: "Mensaje personal",
      call_to_action_button: "Botón de llamada a la acción",
      button_text: "Texto del botón",
      none: "Ninguno",
      preset: "Preestablecido",
      custom: "Personalizado",
      preview: "Vista previa",
      share: "Compartir",
      personalize: "Personalizar",
      view: "Ver",
      complete_your_profile: "Completa tu perfil",
      personal_details: "Detalles personales",
      customize_content_experience: "Personaliza la experiencia de contenido",
      share_on_whatsapp: "Compartir en WhatsApp",
      language: "Idioma",
      ai_description: "¡Nuestro asistente de IA está escribiendo algo solo para ti! Simplemente copia, pega, edita y comparte.",
      ai_regenerate: "Prueba algo diferente",
      copy_text_create_post: "Copiar y pegar en Facebook",
      copy_link_to_content_experience: "Copiar enlace a tu experiencia de contenido",
      email: "Correo electrónico",
      qr_code: "Código QR",
      link: "Enlace",
      my_experiences: "Mis experiencias",
      my_profile: "Mi perfil",
      about_my_business: "Acerca de mi negocio",
      i_am_a_travel_advisor: "Soy un agente de viajes/una agencia o vendedor de viajes de terceros similar",
      host_agency: "Agencia anfitriona",
      agency_association_consortium: "Asociación de agencias / Consorcio / Grupo de gestión",
      booking_email: "Correo electrónico de reservas",
      booking_phone_number: "Número de teléfono de reservas",
      iata_used_for_booking: "IATA utilizado para reservas",
      clia_used_for_booking: "CLIA utilizado para reservas",
      company_name: "Nombre de la empresa",
      business_languages: "Idiomas en los que realizo negocios",
      update: "Actualizar",
      saving: "Guardando",
      saved: "Guardado",
      logout: "Cerrar sesión",
      cobrand_and_share: "Personalizar y compartir",
      your_content_experiences: "Tus experiencias de contenido",
      your_experiences: "Tus experiencias",
      new_experiences: "Nuevas experiencias",
      all_content_experiences: "Todas las experiencias de contenido",
      search: "Buscar",
      experiences: "Experiencias",
      cobranded_website: "Sitio web de marca compartida",
      about: "Acerca de",
      your_partnerName_content_experiences: "Tus experiencias de contenido :partnerName",
      content_experiences_description:
        "Las experiencias de contenido te permiten entregar rápidamente contenido inspirador de una manera altamente personalizada. **Haz clic en una experiencia abajo para empezar.**",
      cobrand_the_partnerName_website: "Personaliza el sitio web de :partnerName",
      cobrand_website_url_description:
        "Puedes agregar fácilmente tus datos de contacto al sitio web de :partnerName para compartirlos con tus clientes. Y no te preocupes, lo hemos personalizado para que todas las solicitudes de reserva vayan a ti.",
      copy_link: "Copiar enlace",
      copied: "Copiado",
      cobrand_website_any_url_description:
        "¡Se pone aún mejor! También puedes personalizar cualquier subpágina en el sitio web de :partnerName. Solo ingresa cualquier enlace de :partnerName para obtener tu enlace co-brandeado.",
      cobrand: "Personalizar",
      contact: "Contacto",
      for_travel_brands: "Para marcas de viajes",
      recommend: "Recomendar",
      recommend_us_to_suppliers: "Recomiéndanos a otros socios",
      recommend_us_description:
        "¿No sería genial si todos tus socios hicieran que el marketing fuera tan fácil? Hazles saber cómo pueden crear fácilmente contenido que apoye tu marketing. ¡Haz clic en el botón y deja que creemos un correo electrónico para ti!",
      cancel: "Cancelar",
      copy_and_paste_in_email: "Copiar (y pegar en un correo electrónico)",
      pagination: ":itemsFrom - :itemsTo de :itemsTotal artículos.",
      choose_a_content_experience: "Elige una experiencia de contenido",
      sign_in: "Iniciar sesión",
      get_started: "Comenzar",
      sign_in_to_account: "Inicia sesión en tu cuenta",
      sign_in_description: "Ingresa tu correo electrónico y te enviaremos un enlace que te iniciará sesión automáticamente.",
      email_address: "Dirección de correo electrónico",
      no_account: "¿No tienes cuenta?",
      sign_up_title: "Regístrate para comenzar a compartir experiencias personalizadas",
      sign_up_description: "En cuestión de segundos, puedes personalizar y compartir nuestras experiencias de contenido para hacer crecer tu negocio.",
      create_account: "Crear cuenta",
      personalize_your_content_experience: "Personaliza esta experiencia de contenido",
      invitation_description:
        "Hemos diseñado esta experiencia de contenido para potenciar tu marketing y aumentar la conversión. Es fácil de personalizar — solo ingresa tu correo electrónico para comenzar!",
      enter_email_legal:
        "Al ingresar tu dirección de correo electrónico, aceptas los [términos de servicio](https://approachguides.com/terms/) y la [política de privacidad](https://approachguides.com/privacy/)",
      and: "y",
      magic_link_email_sent_title: "¡Revisa tu correo electrónico!",
      magic_link_email_sent_description: "Para comenzar, haz clic en el botón del enlace mágico en el correo que enviamos a **:userEmail**",
      magic_link_legal:
        "Al hacer clic en el enlace mágico, aceptas los [términos de servicio](https://approachguides.com/terms/) y la [política de privacidad](https://approachguides.com/privacy/)",
      preview_description: "Consejo: Usa un navegador en modo incógnito para ver lo que ven tus clientes.",
      name: "Nombre",
      logo_personal_photo: "Logo y Foto personal",
      location: "Ubicación",
      consortium: "Consorcio",
      booking_email_phone: "Correo electrónico y teléfono de reservas",
      home: "Inicio",
      update_profile: "Actualizar perfil",
      profile_instructions: "Por favor confirma o llena los campos resaltados.",
      select: "Seleccionar",
      country: "País",
      state_region: "Estado / Región",
      homepage_title: "Lleva tu marketing y ventas al siguiente nivel",
      homepage_subtitle: "con experiencias de contenido personalizadas gratuitas",
      share_qr_code_description: "Descarga el código o haz una captura de pantalla para compartirlo aún más rápido.",
      download_qr_code: "Descargar código QR",
      bilbert_email_start: "Estoy creando un correo electrónico, ¡personalmente para ti! Dame unos segundos",
      bilbert_email_end: "¡Listo! Simplemente haz clic en el botón abajo para copiarlo, luego pégalo en un correo electrónico y envíalo.",
      ai_robot: "Asistente de IA",
      sign_up: "Registrarse",
      already_signed_up: "¿Ya estás registrado?",
      cookies_description: "Usamos cookies para mejorar tu experiencia.",
      learn_more: "Aprender más",
      accept: "Aceptar",
      hooray: "¡Hurra!",
      experience_creation_description: "Danos un momento mientras creamos tu experiencia personalizada...",
      share_link_everywhere: "Comparte tu enlace en todas partes",
      sales_links_header: "Enlaces para compartir con socios",
      sales_links_description:
        "¿Asistiendo a una reunión o evento, haciendo un seminario web, enviando un correo electrónico? Este es un recurso completo y actualizado con todo lo que necesitas para invitar a los socios a personalizar y compartir tus experiencias de contenido.",
      step_one_header: "Copia el enlace de invitación o descarga el código QR",
      step_one_description:
        "Copia el enlace de invitación, descarga el código QR para todas las páginas de contenido de :partnerName, o elige una página de contenido específica.",
      step_one_tip: "¡No olvides **probar** tu código QR antes de compartirlo!",
      copy_invite_link: "Copiar enlace de invitación",
      copied_to_clipboard: "Copiado al portapapeles",
      or_choose_content_experience: "O elige una experiencia de contenido específica:",
      step_two_header: "Incrusta en presentaciones, correos electrónicos y publicaciones en redes sociales",
      step_two_description:
        "Incrusta en tu presentación del webinar, en tu correo electrónico, en tu publicación en redes sociales... o en cualquier lugar donde interactúes con los socios.",
      step_three_header: "Añade un texto explicativo",
      step_three_description: "Añade un texto explicativo para ayudar a los socios a entender por qué estás compartiendo estos enlaces y códigos QR.",
      step_three_tip: "¡No olvides incluir tu enlace y/o código QR!",
      sales_example_one: "La forma más fácil de vender :partnerName. ¡Haz clic aquí para comenzar!",
      sales_example_two: "Haz clic para personalizar y compartir contenido web al instante.",
      sales_example_three: "Haz clic para comenzar a promocionar :partnerName al instante",
      just_a_moment: "Un momento",
      thats_strange: "Eso es extraño...",
      invalid_magic_link: "Este enlace mágico no es válido. Por favor, haz clic nuevamente en el botón del enlace mágico o pega la URL mágica asociada",
      oops: "¡Ups!",
      magic_link_used:
        "Los enlaces mágicos son para un solo uso y este ya ha sido utilizado. Por favor, vuelve a ingresar tu correo electrónico para recibir un nuevo enlace mágico.",
      get_new_magic_link: "¡Obtén tu nuevo enlace mágico!",
      new_magic_link_sent: "Tu enlace mágico expiró, así que te hemos enviado uno nuevo.",
      magic_link_check_email: "Revisa tu correo electrónico y haz clic para iniciar sesión",
      cobrand_our_website: "Personaliza nuestro sitio web",
      required_field: "Este campo es obligatorio.",
      sales_example_description: "Aquí tienes algunos ejemplos para empezar:",
      confirm: "Confirmar",
      your: "Tu",
      you_email_com: "tu",
      upload_image: "Subir imagen...",
      demo_phone_number: "+34 93 555 1212",
    },
    pt: {
      first_name: "Nome",
      last_name: "Sobrenome",
      phone_number: "Número de telefone",
      logo: "Logo",
      personal_photo: "Foto pessoal",
      personal_message: "Mensagem pessoal",
      call_to_action_button: "Botão de chamada à ação",
      button_text: "Texto do botão",
      none: "Nenhum",
      preset: "Predefinido",
      custom: "Personalizado",
      preview: "Pré-visualizar",
      share: "Compartilhar",
      personalize: "Personalizar",
      view: "Visualizar",
      complete_your_profile: "Complete seu perfil",
      personal_details: "Detalhes pessoais",
      customize_content_experience: "Personalizar experiência de conteúdo",
      share_on_whatsapp: "Compartilhar no WhatsApp",
      language: "Idioma",
      ai_description: "Nosso assistente de IA está escrevendo algo só para você! Basta copiar, colar, editar e compartilhar.",
      ai_regenerate: "Tente algo diferente",
      copy_text_create_post: "Copiar e colar no Facebook",
      copy_link_to_content_experience: "Copiar link da sua experiência de conteúdo",
      email: "E-mail",
      qr_code: "Código QR",
      link: "Link",
      my_experiences: "Minhas experiências",
      my_profile: "Meu perfil",
      about_my_business: "Sobre meu negócio",
      i_am_a_travel_advisor: "Sou um agente de viagens/agência ou vendedor de terceiros similar",
      host_agency: "Agência anfitriã",
      agency_association_consortium: "Consorcio / Grupo de gestión",
      booking_email: "E-mail de reserva",
      booking_phone_number: "Número de telefone de reserva",
      iata_used_for_booking: "IATA usado para reserva",
      clia_used_for_booking: "CLIA usado para reserva",
      company_name: "Nome da empresa",
      business_languages: "Idiomas em que faço negócios",
      update: "Atualizar",
      saving: "Salvando",
      saved: "Salvo",
      logout: "Sair",
      cobrand_and_share: "Personalizar co-branded e compartilhar",
      your_content_experiences: "Suas experiências de conteúdo",
      your_experiences: "Suas experiências",
      new_experiences: "Novas experiências",
      all_content_experiences: "Todas as experiências de conteúdo",
      search: "Buscar",
      experiences: "Experiências",
      cobranded_website: "Site de marca conjunta (co-branded)",
      about: "Sobre",
      your_partnerName_content_experiences: "Suas experiências de conteúdo :partnerName",
      content_experiences_description:
        "As experiências de conteúdo permitem que você entregue rapidamente conteúdo inspirador de uma maneira altamente personalizada. **Clique em uma experiência abaixo para começar.**",
      cobrand_the_partnerName_website: "Personalizar co-branded o site de :partnerName",
      cobrand_website_url_description:
        "Você pode facilmente adicionar seus dados de contato ao site de :partnerName para compartilhar com seus clientes. E não se preocupe, personalizamos para que todas as solicitações de reserva vão para você.",
      copy_link: "Copiar link",
      copied: "Copiado",
      cobrand_website_any_url_description:
        "Fica ainda melhor! Você também pode personalizar qualquer subpágina no site de :partnerName. Basta inserir qualquer URL de :partnerName para obter seu link co-branded.",
      cobrand: "Personalizar",
      contact: "Contato",
      for_travel_brands: "Para marcas de viagens",
      recommend: "Recomendar",
      recommend_us_to_suppliers: "Recomende-nos a outros parceiros",
      recommend_us_description:
        "Não seria ótimo se todos os seus parceiros tornassem o marketing tão fácil? Avise-os sobre como podem criar facilmente conteúdo que apoie o seu marketing. Clique no botão e deixe-nos criar um e-mail para você!",
      cancel: "Cancelar",
      copy_and_paste_in_email: "Copiar (e colar em um e-mail)",
      pagination: ":itemsFrom - :itemsTo de :itemsTotal itens.",
      choose_a_content_experience: "Escolha uma experiência de conteúdo",
      sign_in: "Entrar",
      get_started: "Começar",
      sign_in_to_account: "Entrar na sua conta",
      sign_in_description: "Digite seu e-mail e enviaremos um link que fará o login automaticamente.",
      email_address: "Endereço de e-mail",
      no_account: "Não tem uma conta?",
      sign_up_title: "Cadastre-se para começar a compartilhar experiências personalizadas",
      sign_up_description: "Em segundos, você pode personalizar com a sua marca e compartilhar nossas experiências de conteúdo para crescer seu negócio.",
      create_account: "Criar conta",
      personalize_your_content_experience: "Personalize esta experiência de conteúdo",
      invitation_description:
        "Criamos esta experiência de conteúdo para impulsionar seu marketing e aumentar a conversão. É fácil de personalizar—basta inserir seu e-mail para começar!",
      enter_email_legal:
        "Ao inserir seu endereço de e-mail, você concorda com os [termos de serviço](https://approachguides.com/terms/) e a [política de privacidade](https://approachguides.com/privacy/)",
      and: "e",
      magic_link_email_sent_title: "Confira seu e-mail!",
      magic_link_email_sent_description: "Para começar, clique no botão do link mágico no e-mail que enviamos para **:userEmail**",
      magic_link_legal:
        "Ao clicar no link mágico, você concorda com os [termos de serviço](https://approachguides.com/terms/) e a [política de privacidade](https://approachguides.com/privacy/)",
      preview_description: "Dica: Use um navegador em modo incógnito para ver a visão dos seus clientes.",
      name: "Nome",
      logo_personal_photo: "Logo e Foto pessoal",
      location: "Localização",
      consortium: "Consórcio",
      booking_email_phone: "E-mail e telefone de reserva",
      home: "Início",
      update_profile: "Atualizar perfil",
      profile_instructions: "Por favor, confirme ou preencha os campos destacados.",
      select: "Selecionar",
      country: "País",
      state_region: "Estado / Região",
      homepage_title: "Leve seu marketing e vendas para o próximo nível",
      homepage_subtitle: "com experiências de conteúdo personalizadas gratuitas",
      share_qr_code_description: "Baixe o código ou faça uma captura de tela para compartilhar ainda mais rápido.",
      download_qr_code: "Baixar código QR",
      bilbert_email_start: "Estou criando um e-mail, pessoalmente para você! Me dê alguns segundos",
      bilbert_email_end: "Tudo pronto! Clique no botão abaixo para copiar, e depois cole no e-mail e envie!",
      ai_robot: "Assistente de IA",
      sign_up: "Cadastrar",
      already_signed_up: "Já se cadastrou?",
      cookies_description: "Usamos cookies para melhorar sua experiência.",
      learn_more: "Saiba mais",
      accept: "Aceitar",
      hooray: "Viva!",
      experience_creation_description: "Nos dê um momento enquanto criamos sua experiência personalizada...",
      share_link_everywhere: "Compartilhe seu link em todos os lugares",
      sales_links_header: "Links para compartilhar com parceiros",
      sales_links_description:
        "Participando de uma reunião ou evento, fazendo um seminário web, enviando um e-mail? Este é um recurso completo e atualizado com tudo o que você precisa para convidar parceiros a personalizar e compartilhar suas experiências de conteúdo.",
      step_one_header: "Copie o link de convite ou baixe o código QR",
      step_one_description:
        "Copie o link de convite, baixe o código QR para todas as páginas de conteúdo de :partnerName, ou escolha uma página de conteúdo específica.",
      step_one_tip: "Não se esqueça de **testar** seu código QR antes de compartilhar!",
      copy_invite_link: "Copiar link de convite",
      copied_to_clipboard: "Copiado para a área de transferência",
      or_choose_content_experience: "Ou escolha uma experiência de conteúdo específica:",
      step_two_header: "Incorpore em apresentações, e-mails e postagens nas redes sociais",
      step_two_description:
        "Incorpore em sua apresentação de webinar, e-mail, postagem nas redes sociais... ou em qualquer lugar onde interaja com os parceiros.",
      step_three_header: "Adicione algum texto explicativo",
      step_three_description: "Adicione algum texto explicativo para ajudar os parceiros a entender por que você está compartilhando esses links e códigos QR.",
      step_three_tip: "Não se esqueça de incluir seu link e/ou código QR!",
      sales_example_one: "A maneira mais fácil de vender :partnerName. Clique aqui para começar!",
      sales_example_two: "Clique para personalizar e compartilhar conteúdo web instantaneamente.",
      sales_example_three: "Clique para começar a promover :partnerName instantaneamente",
      just_a_moment: "Um momento",
      thats_strange: "Isso é estranho...",
      invalid_magic_link: "Este link mágico é inválido. Por favor, clique novamente no botão do link mágico ou cole a URL mágica associada",
      oops: "Ops!",
      magic_link_used: "Links mágicos são para uso único e este já foi usado. Por favor, insira novamente seu e-mail para receber um novo link mágico.",
      get_new_magic_link: "Obtenha seu novo link mágico!",
      new_magic_link_sent: "Seu link mágico expirou, então enviamos um novo para você.",
      magic_link_check_email: "Verifique seu e-mail e clique para fazer login",
      cobrand_our_website: "Personalize nosso site",
      required_field: "Este campo é obrigatório.",
      sales_example_description: "Aqui estão alguns exemplos para começar:",
      confirm: "Confirmar",
      your: "Seu",
      you_email_com: "você",
      upload_image: "Carregar imagem...",
      demo_phone_number: "+351 21 555 1212",
    },
    fr: {
      first_name: "Prénom",
      last_name: "Nom",
      phone_number: "Numéro de téléphone",
      logo: "Logo",
      personal_photo: "Photo personnelle",
      personal_message: "Message personnel",
      call_to_action_button: "Bouton avec lien",
      button_text: "Texte du bouton",
      none: "Aucun",
      preset: "Préréglé",
      custom: "Personnalisé",
      preview: "Aperçu",
      share: "Partager",
      personalize: "Personnaliser",
      view: "Voir",
      complete_your_profile: "Complétez votre profil",
      personal_details: "Informations de contact",
      customize_content_experience: "Personnaliser l'expérience de contenu",
      share_on_whatsapp: "Partager sur WhatsApp",
      language: "Langue",
      ai_description: "Notre assistant AI écrit quelque chose spécialement pour vous! Copiez, collez, éditez et partagez.",
      ai_regenerate: "Essayez quelque chose de différent",
      copy_text_create_post: "Copiez et collez sur Facebook",
      copy_link_to_content_experience: "Copiez le lien vers votre expérience de contenu",
      email: "E-mail",
      qr_code: "Code QR",
      link: "Lien",
      my_experiences: "Mes expériences",
      my_profile: "Mon profil",
      about_my_business: "À propos de mon entreprise",
      i_am_a_travel_advisor: "Je suis un conseiller en voyages/une agence de voyages ou un vendeur tiers similaire",
      host_agency: "Agence hôte",
      agency_association_consortium: "Association d'agences / Consortium",
      booking_email: "E-mail utilisé pour la réservation",
      booking_phone_number: "Numéro de téléphone utilisé pour la réservation",
      iata_used_for_booking: "IATA utilisé pour la réservation",
      clia_used_for_booking: "CLIA utilisé pour la réservation",
      company_name: "Nom de l'entreprise",
      business_languages: "Langues dans lesquelles je fais des affaires",
      update: "Mettre à jour",
      saving: "Enregistrement",
      saved: "Enregistré",
      logout: "Déconnexion",
      cobrand_and_share: "Personnaliser et partager",
      your_content_experiences: "Vos expériences de contenu",
      your_experiences: "Vos expériences",
      new_experiences: "Nouvelles expériences",
      all_content_experiences: "Toutes les expériences de contenu",
      search: "Recherche",
      experiences: "Expériences",
      cobranded_website: "Site web personnalisé",
      about: "À propos",
      your_partnerName_content_experiences: "Vos expériences de contenu :partnerName",
      content_experiences_description:
        "Les expériences de contenu vous permettent de partager rapidement des contenus inspirants de manière très personnalisée. **Cliquez sur une expérience de contenu ci-dessous pour commencer.**",
      cobrand_the_partnerName_website: "Personnalisez le site web de :partnerName",
      cobrand_website_url_description:
        "Voici votre site web personnalisé :partnerName. Partagez ce lien avec vos clients - nous avons transformé le site juste pour vous.",
      copy_link: "Copier le lien",
      copied: "Copié",
      cobrand_website_any_url_description:
        "C'est encore mieux! Vous pouvez personnaliser n'importe quelle page sur le site de :partnerName. Il suffit d'entrer n'importe quelle URL de :partnerName pour obtenir votre lien personnalisé.",
      cobrand: "Personnaliser",
      contact: "Contact",
      for_travel_brands: "Pour les marques de voyage",
      recommend: "Recommander",
      recommend_us_to_suppliers: "Recommandez-nous à vos partenaires préférés",
      recommend_us_description:
        "Vous ne souhaitez pas que tous vos partenaires facilitent le marketing de cette manière? Faites-leur savoir comment ils peuvent facilement créer du contenu qui élève votre marketing. Cliquez sur le bouton et laissez-nous rédiger un e-mail pour vous!",
      cancel: "Annuler",
      copy_and_paste_in_email: "Copier (et coller dans un e-mail)",
      pagination: ":itemsFrom - :itemsTo de :itemsTotal articles.",
      choose_a_content_experience: "Choisir une expérience de contenu",
      sign_in: "Se connecter",
      get_started: "Commencer",
      sign_in_to_account: "Se connecter à votre compte",
      sign_in_description: "Entrez votre e-mail et nous vous enverrons un lien qui vous connecte automatiquement.",
      email_address: "Adresse e-mail",
      no_account: "Vous n'avez pas de compte?",
      sign_up_title: "Inscrivez-vous pour commencer à partager du contenu personnalisé",
      sign_up_description:
        "En quelques secondes, vous pouvez personnaliser et partager nos expériences de contenu pour faire passer votre marketing au niveau supérieur.",
      create_account: "Créer un compte",
      personalize_your_content_experience: "Personnalisez cette expérience de contenu",
      invitation_description:
        "Nous avons créé cette expérience de contenu pour améliorer votre marketing et vous aider à gagner plus. C'est facile à personnaliser – il vous suffit de saisir votre e-mail pour commencer!",
      enter_email_legal:
        "En entrant votre adresse e-mail, vous acceptez les [conditions d'utilisation](https://approachguides.com/terms/) et la [politique de confidentialité](https://approachguides.com/privacy/)",
      and: "et",
      magic_link_email_sent_title: "Vérifiez votre e-mail!",
      magic_link_email_sent_description: "Cliquez sur le lien magique que nous avons envoyé à **:userEmail**",
      magic_link_legal:
        "En cliquant sur le lien magique, vous acceptez les [conditions d'utilisation](https://approachguides.com/terms/) et la [politique de confidentialité](https://approachguides.com/privacy/)",
      preview_description: "Astuce: utilisez un navigateur en mode incognito pour voir ce que voient vos clients.",
      name: "Nom",
      logo_personal_photo: "Logo et photo personnelle",
      location: "Emplacement",
      consortium: "Consortium",
      booking_email_phone: "E-mail et téléphone pour la réservation",
      home: "Accueil",
      update_profile: "Mettre à jour le profil",
      profile_instructions: "Veuillez confirmer ou remplir les champs mis en évidence.",
      select: "Sélectionner",
      country: "Pays",
      state_region: "État / Région",
      homepage_title: "Améliorez votre marketing et vos ventes",
      homepage_subtitle: "avec des expériences de contenu personnalisées gratuites",
      share_qr_code_description: "Téléchargez le code QR ou faites une capture d'écran pour un partage plus rapide.",
      download_qr_code: "Télécharger le code QR",
      bilbert_email_start: "Je crée un e-mail spécialement pour vous! Donnez-moi quelques secondes",
      bilbert_email_end: "Tout est fait! Cliquez sur le bouton ci-dessous pour copier le texte, puis collez-le dans un e-mail et envoyez-le!",
      ai_robot: "Assistant AI",
      sign_up: "S'inscrire",
      already_signed_up: "Déjà inscrit?",
      cookies_description: "Nous utilisons des cookies pour améliorer votre expérience.",
      learn_more: "En savoir plus",
      accept: "Accepter",
      hooray: "Hourra!",
      experience_creation_description: "Donnez-nous un moment pendant que nous créons votre expérience personnalisée...",
      share_link_everywhere: "Partagez votre lien partout",
      sales_links_header: "Liens à partager avec les partenaires",
      sales_links_description:
        "Assister à une réunion ou un événement, organiser un webinaire, envoyer un e-mail? Voici tout ce dont vous avez besoin pour inviter des partenaires à personnaliser et partager vos expériences de contenu.",
      step_one_header: "Copier le lien d'invitation ou télécharger le code QR",
      step_one_description:
        "Copiez le lien d'invitation ou téléchargez le code QR pour toutes les expériences de contenu :partnerName, ou choisissez une expérience de contenu spécifique.",
      step_one_tip: "N'oubliez pas de **tester** votre code QR avant de le partager!",
      copy_invite_link: "Copier le lien d'invitation",
      copied_to_clipboard: "Copié dans le presse-papiers",
      or_choose_content_experience: "Ou choisissez une expérience de contenu spécifique:",
      step_two_header: "Ajoutez-le à votre présentation de webinaire, à vos e-mails et à vos publications sur les réseaux sociaux",
      step_two_description:
        "Ajoutez-le à votre présentation de webinaire, à votre e-mail, à votre publication sur les réseaux sociaux... partout où vous interagissez avec des partenaires commerciaux.",
      step_three_header: "Ajoutez un texte explicatif",
      step_three_description: "Ajoutez un texte explicatif pour aider vos partenaires à comprendre pourquoi vous partagez les liens et les codes QR.",
      step_three_tip: "N'oubliez pas de coller votre lien et/ou code QR!",
      sales_example_one: "C'est le moyen le plus simple de vendre :partnerName. Cliquez pour commencer!",
      sales_example_two: "Cliquez pour personnaliser et partager instantanément notre dernière expérience de contenu.",
      sales_example_three: "Cliquez pour commencer à vendre :partnerName immédiatement",
      just_a_moment: "Un instant",
      thats_strange: "C'est étrange...",
      invalid_magic_link: "Ce lien magique est invalide. Veuillez cliquer à nouveau sur le bouton du lien magique ou coller l'URL",
      oops: "Oups!",
      magic_link_used:
        "Les liens magiques sont à usage unique et celui-ci a déjà été utilisé. Veuillez saisir à nouveau votre adresse e-mail pour recevoir un nouveau lien magique.",
      get_new_magic_link: "Obtenez votre nouveau lien magique!",
      new_magic_link_sent: "Votre lien magique a expiré, nous vous avons envoyé un nouveau.",
      magic_link_check_email: "Vérifiez votre e-mail et cliquez pour vous connecter",
      cobrand_our_website: "Personnalisez notre site web",
      required_field: "Ce champ est requis.",
      sales_example_description: "Voici quelques exemples pour vous aider à démarrer:",
      confirm: "Confirmer",
      your: "Votre",
      you_email_com: "tu",
      upload_image: "Télécharger l'image...",
      demo_phone_number: "+33 1 55 55 12 12",
    },
    de: {
      first_name: "Vorname",
      last_name: "Nachname",
      phone_number: "Telefonnummer",
      logo: "Logo deiner Agentur",
      personal_photo: "Persönliches Foto",
      personal_message: "Persönliche Nachricht an deine Kunden",
      call_to_action_button: "Call-to-Action-Button",
      button_text: "Button-Text",
      none: "Keiner",
      preset: "Standard",
      custom: "Personalisiert",
      preview: "Vorschau",
      share: "Teilen",
      personalize: "Personalisieren",
      view: "Ansehen",
      complete_your_profile: "Vervollständige dein Profil",
      personal_details: "Persönliche Details",
      customize_content_experience: "Web-Content anpassen",
      share_on_whatsapp: "Auf WhatsApp teilen",
      language: "Sprache",
      ai_description: "Unser KI-Assistent schreibt gerade einen Text nur für dich! Einfach kopieren, einfügen, ggf. bearbeiten und teilen.",
      ai_regenerate: "Neu schreiben",
      copy_text_create_post: "Kopieren & einfügen auf Facebook",
      copy_link_to_content_experience: "Link kopieren",
      email: "E-Mail",
      qr_code: "QR-Code",
      link: "Link",
      my_experiences: "Mein Web-Content",
      my_profile: "Mein Profil",
      about_my_business: "Über meine Agentur",
      i_am_a_travel_advisor: "Ich bin ein Reiseberater/Reiseagentur oder ein ähnlicher Drittanbieter von Reisen",
      host_agency: "Host-Agentur",
      agency_association_consortium: "Agenturverband / Konsortium",
      booking_email: "Buchungs-E-Mail-Adresse",
      booking_phone_number: "Buchungs-Telefonnummer",
      iata_used_for_booking: "IATA für Buchungen verwendet",
      clia_used_for_booking: "CLIA für Buchungen verwendet",
      company_name: "Firmenname",
      business_languages: "Sprachen, in denen ich Geschäfte mache",
      update: "Aktualisieren",
      saving: "Speichern",
      saved: "Gespeichert",
      logout: "Abmelden",
      cobrand_and_share: "Personalisieren und teilen",
      your_content_experiences: "Meine persönlichen Content-Seiten",
      your_experiences: "Veröffentlichte Content-Seiten",
      new_experiences: "Weitere verfügbare Content-Seiten",
      all_content_experiences: "Alle Content-Seiten",
      search: "Suche",
      experiences: "Personalisierter Web-Content",
      cobranded_website: "Co-Branded Webseite",
      about: "Über uns",
      your_partnerName_content_experiences: "Deine :partnerName Content-Seiten",
      content_experiences_description:
        "Unsere Content-Seiten ermöglichen es dir, schnell und einfach inspirierende Web-Inhalte zu personalisieren und mit deinen Kunden zu teilen. **Klicke auf eine der verfügbaren Content-Seiten unten, um zu beginnen.**",
      cobrand_the_partnerName_website: "Co-brand die :partnerName Website",
      cobrand_website_url_description:
        "Mit :partnerName-Website kannst du unsere Webseite ganz einfach um deine Kontaktdaten ergänzen um sie mit deinen Kunden zu teile. Und keine Sorge, wir haben sie so angepasst, dass alle Buchungsanfragen zu dir zurückfließen.",
      copy_link: "Link kopieren",
      copied: "Kopiert",
      cobrand_website_any_url_description:
        "Es wird noch besser! Du kannst auch jede Unterseite auf der :partnerName-Webseite co-branden. Gib einfach eine beliebige :partnerName-URL ein, um deinen Co-Branding-Link zu erhalten.",
      cobrand: "Co-Branden",
      contact: "Kontakt",
      for_travel_brands: "Für Reiseunternehmen",
      recommend: "Empfehlen",
      recommend_us_to_suppliers: "Empfehle uns weiteren Partnern",
      recommend_us_description:
        "Wäre es nicht schön, wenn alle deine Partner das Marketing so einfach machen würden? Lass sie wissen, wie sie ganz einfach Inhalte erstellen können, die dein Marketing unterstützen. Klick auf den Button und lass uns eine E-Mail für dich erstellen!",
      cancel: "Abbrechen",
      copy_and_paste_in_email: "Kopieren (und in eine E-Mail einfügen)",
      pagination: ":itemsFrom - :itemsTo von :itemsTotal Artikeln.",
      choose_a_content_experience: "Wähle eine Content-Seite",
      sign_in: "Anmelden",
      get_started: "Loslegen",
      sign_in_to_account: "Melde dich in deinem Konto an",
      sign_in_description: "Gib deine E-Mail-Adresse ein und wir senden dir einen Link, der dich automatisch anmeldet.",
      email_address: "E-Mail-Adresse",
      no_account: "Kein Konto?",
      sign_up_title: "Melde dich an, um ganz einfach personalisierten Web-Content zu teilen",
      sign_up_description: "In wenigen Sekunden kannst du unsere vorgefertigten Content-Seiten personalisieren und teilen, um deine Geschäfte anzukurbeln.",
      create_account: "Konto erstellen",
      personalize_your_content_experience: "Personalisiere diese Content-Seite",
      invitation_description:
        "Wir haben diese Content-Seite entwickelt, um deine Vertriebsarbeit zu unterstützen und deine Konversion zu steigern. Einfacher könnte der Einstieg kaum sein, gib einfach deine E-Mail-Adresse ein, um zu beginnen!",
      enter_email_legal:
        "Indem Sie Ihre E-Mail-Adresse eingeben, stimmen Sie den [Nutzungsbedingungen](https://approachguides.com/terms/) und der [Datenschutzrichtlinie](https://approachguides.com/privacy/) zu",
      and: "und",
      magic_link_email_sent_title: "Überprüfe deine E-Mail!",
      magic_link_email_sent_description: "Um zu starten, klicke auf den Magic-Link-Button in der E-Mail, die wir an **:userEmail** gesendet haben",
      magic_link_legal:
        "Indem Sie auf den Magic-Link klicken, stimmen Sie den [Nutzungsbedingungen](https://approachguides.com/terms/) und der [Datenschutzrichtlinie](https://approachguides.com/privacy/) zu",
      preview_description: "Tipp: Verwenden Sie einen Inkognito-Browser, um die Ansicht für Ihre Kunden zu sehen.",
      name: "Name",
      logo_personal_photo: "Logo und persönliches Foto",
      location: "Standort",
      consortium: "Konsortium",
      booking_email_phone: "Buchungs-E-Mail und -Telefonnummer",
      home: "Startseite",
      update_profile: "Profil aktualisieren",
      profile_instructions: "Bestätige oder fülle die hervorgehobenen Felder aus.",
      select: "Auswählen",
      country: "Land",
      state_region: "Bundesland/Region",
      homepage_title: "Bring dein Marketing auf ein neues Level",
      homepage_subtitle: "mit kostenlosem, personalisiertem Web-Content",
      share_qr_code_description: "Lade dir den Code herunter oder mache einen Screenshot, um noch schneller zu teilen.",
      download_qr_code: "QR-Code herunterladen",
      bilbert_email_start: "Ich erstelle grade eine E-Mail nur für dich! Bitte gib mir ein paar Sekunden",
      bilbert_email_end: "Fertig! Klick einfach auf den Button unten, um den Text zu kopieren, füge ihn dann in eine E-Mail ein und verschicke sie!",
      ai_robot: "KI-Assistent",
      sign_up: "Anmelden",
      already_signed_up: "Bereits angemeldet?",
      cookies_description: "Wir verwenden Cookies, um deine Erfahrung zu verbessern.",
      learn_more: "Mehr erfahren",
      accept: "Akzeptieren",
      hooray: "Hurra!",
      experience_creation_description: "Gib uns einen Moment, während wir deine Content-Seite erstellen...",
      share_link_everywhere: "Teile deinen Link überall",
      sales_links_header: "Links zum Teilen mit Partnern",
      sales_links_description:
        "Nimmst du an einem Meeting oder Event teil, willst ein Webinar veranstalten oder eine E-Mail senden? Dies ist die vollständige, aktuelle Seite mit allem, was du benötigst, um Partner zum Co-Branding einzuladen und deine Content-Seiten zu teilen.",
      step_one_header: "Kopiere den Einladungs-Link oder lade den QR-Code herunter",
      step_one_description:
        "Kopiere den Einladungs-Link, lade den QR-Code für alle :partnerName-Content-Seiten herunter oder wähle eine spezifische Content-Seite aus.",
      step_one_tip: "Vergiss nicht, deinen QR-Code vor dem Teilen **zu testen**!",
      copy_invite_link: "Einladung-Link kopieren",
      copied_to_clipboard: "In die Zwischenablage kopiert",
      or_choose_content_experience: "Oder wählen Sie eine spezifische Inhaltserfahrung aus:",
      step_two_header: "In Präsentationen, E-Mails und Social-Media-Posts einbetten",
      step_two_description: "Einbinden in deine Webinar-Präsentation, E-Mail, deinen Social Media Post ... oder überall dort, wo du mit Partnern interagierst.",
      step_three_header: "Füge einen Erklärungstext hinzu",
      step_three_description: "Füge einen Erklärungstext hinzu, um Partnern zu helfen, zu verstehen, warum du diese Links und QR-Codes teilst.",
      step_three_tip: "Vergiss nicht, deinen Link und/oder QR-Code einzufügen!",
      sales_example_one: "Der einfachste Weg, :partnerName zu verkaufen. Klicke hier, um zu starten!",
      sales_example_two: "Klicke, um sofort Web-Content zu personalisieren und zu teilen.",
      sales_example_three: "Klicke, um sofort mit dem Bewerbenvon :partnerName zu beginnen",
      just_a_moment: "Einen Moment",
      thats_strange: "Das ist seltsam...",
      invalid_magic_link: "Dieser magische Link ist ungültig. Klicke erneut auf den Magic-Link-Button oder füge die zugehörige Magic-URL ein",
      oops: "Ups!",
      magic_link_used:
        "Magic-Links sind nur einmalig verwendbar - und dieser wurde bereits genutzt. Bitte gib deine  E-Mail-Adresse nochmal ein, um einen neuen magischen Link zu erhalten.",
      get_new_magic_link: "Hol dir deinen neuen Magic-Link!",
      new_magic_link_sent: "Dein Magic-Link ist abgelaufen, daher haben wir dir einen neuen geschickt.",
      magic_link_check_email: "Überprüfe deine E-Mail und klicke, um dich anzumelden",
      cobrand_our_website: "Personalisiere unsere Website",
      required_field: "Dieses Feld ist erforderlich.",
      sales_example_description: "Hier sind einige Beispiele, um dir den Einstieg zu erleichtern:",
      confirm: "Bestätigen",
      your: "Ihr",
      you_email_com: "du",
      upload_image: "Bild hochladen...",
      demo_phone_number: "+49 30 555 1212",
    },
    zh: {
      first_name: "名字",
      last_name: "姓氏",
      phone_number: "电话号码",
      logo: "Logo",
      personal_photo: "个人照片",
      personal_message: "个人信息",
      call_to_action_button: "带链接的按钮",
      button_text: "按钮文本",
      none: "无",
      preset: "预设",
      custom: "自定义",
      preview: "预览",
      share: "分享",
      personalize: "个性化",
      view: "查看",
      complete_your_profile: "完善您的个人资料",
      personal_details: "联系信息",
      customize_content_experience: "个性化内容体验",
      share_on_whatsapp: "在WhatsApp上分享",
      language: "语言",
      ai_description: "我们的AI助手正在为您编写内容！只需复制、粘贴、编辑并分享。",
      ai_regenerate: "尝试不同的内容",
      copy_text_create_post: "复制并粘贴到Facebook",
      copy_link_to_content_experience: "复制到您的内容体验的链接",
      email: "电子邮件",
      qr_code: "二维码",
      link: "链接",
      my_experiences: "我的体验",
      my_profile: "我的个人资料",
      about_my_business: "关于我的公司",
      i_am_a_travel_advisor: "我是旅行顾问/旅行社或类似的第三方旅行销售商",
      host_agency: "主办机构",
      agency_association_consortium: "代理机构协会/联盟",
      booking_email: "用于预订的电子邮件",
      booking_phone_number: "用于预订的电话号码",
      iata_used_for_booking: "用于预订的IATA",
      clia_used_for_booking: "用于预订的CLIA",
      company_name: "公司名称",
      business_languages: "我所经营的语言",
      update: "更新",
      saving: "保存中",
      saved: "已保存",
      logout: "退出",
      cobrand_and_share: "个性化和分享",
      your_content_experiences: "您的内容体验",
      your_experiences: "您的体验",
      new_experiences: "新的体验",
      all_content_experiences: "所有内容体验",
      search: "搜索",
      experiences: "体验",
      cobranded_website: "个性化网站",
      about: "关于",
      your_partnerName_content_experiences: "您的:partnerName内容体验",
      content_experiences_description: "内容体验使您能够以高度个性化的方式快速分享启发性内容。**点击下面的内容体验以开始。**",
      cobrand_the_partnerName_website: "个性化:partnerName网站",
      cobrand_website_url_description: "这是您的个性化:partnerName网站。与客户分享这个链接——我们为您转换了这个网站。",
      copy_link: "复制链接",
      copied: "已复制",
      cobrand_website_any_url_description: "更棒的是！您可以个性化:partnerName网站上的任何页面。只需输入任意:partnerName URL即可获得您的个性化链接。",
      cobrand: "个性化",
      contact: "联系",
      for_travel_brands: "对于旅行品牌",
      recommend: "推荐",
      recommend_us_to_suppliers: "推荐我们给您的优选合作伙伴",
      recommend_us_description:
        "您不希望所有的合作伙伴都能如此轻松地进行市场营销吗？告诉他们，他们可以轻松创建内容，提升您的营销效果。点击按钮，让我们为您撰写一封邮件！",
      cancel: "取消",
      copy_and_paste_in_email: "复制（并粘贴到电子邮件中）",
      pagination: ":itemsFrom - :itemsTo 共 :itemsTotal 项。",
      choose_a_content_experience: "选择一项内容体验",
      sign_in: "登录",
      get_started: "开始",
      sign_in_to_account: "登录到您的账户",
      sign_in_description: "输入您的电子邮件，我们将发送一个自动登录的链接给您。",
      email_address: "电子邮件地址",
      no_account: "没有账户？",
      sign_up_title: "注册开始分享个性化内容",
      sign_up_description: "只需几秒钟，您就可以个性化并分享我们的内容体验，提升您的营销效果。",
      create_account: "创建账户",
      personalize_your_content_experience: "个性化这个内容体验",
      invitation_description: "我们精心打造了这个内容体验来提升您的营销，并帮助您赚取更多。它很容易个性化——只需输入您的电子邮件即可开始！",
      enter_email_legal: "输入您的电子邮件地址即表示您同意我们的[服务条款](https://approachguides.com/terms/)和[隐私政策](https://approachguides.com/privacy/)",
      and: "和",
      magic_link_email_sent_title: "检查您的电子邮件！",
      magic_link_email_sent_description: "点击我们发送到**:userEmail**的魔法链接",
      magic_link_legal: "点击魔法链接即表示您同意我们的[服务条款](https://approachguides.com/terms/)和[隐私政策](https://approachguides.com/privacy/)",
      preview_description: "提示：使用隐身浏览器查看客户视图。",
      name: "姓名",
      logo_personal_photo: "Logo和个人照片",
      location: "位置",
      consortium: "联盟",
      booking_email_phone: "预订的电子邮件和电话",
      home: "首页",
      update_profile: "更新个人资料",
      profile_instructions: "请确认或填写高亮显示的字段。",
      select: "选择",
      country: "国家",
      state_region: "州/地区",
      homepage_title: "将您的营销和销售提升到新水平",
      homepage_subtitle: "通过免费的个性化内容体验",
      share_qr_code_description: "下载二维码或截屏以便更快速地分享。",
      download_qr_code: "下载二维码",
      bilbert_email_start: "我正在为您制作一封电子邮件！请稍等片刻",
      bilbert_email_end: "完成了！点击下面的按钮复制文本，然后将其粘贴到电子邮件中并发送！",
      ai_robot: "AI助手",
      sign_up: "注册",
      already_signed_up: "已经注册？",
      cookies_description: "我们使用Cookies来改善您的体验。",
      learn_more: "了解更多",
      accept: "接受",
      hooray: "太棒了！",
      experience_creation_description: "稍等片刻，我们正在为您创建个性化体验...",
      share_link_everywhere: "在任何地方分享您的链接",
      sales_links_header: "与合作伙伴分享的链接",
      sales_links_description: "参加会议或活动、做一个网络研讨会、发送电子邮件？这是邀请合作伙伴个性化并分享您的内容体验所需的所有资源。",
      step_one_header: "复制邀请链接或下载二维码",
      step_one_description: "复制邀请链接或下载二维码，选择所有:partnerName的内容体验，或选择特定内容体验。",
      step_one_tip: "不要忘记在分享前**测试**您的二维码！",
      copy_invite_link: "复制邀请链接",
      copied_to_clipboard: "复制到剪贴板",
      or_choose_content_experience: "或选择特定的内容体验：",
      step_two_header: "放入您的网络研讨会演示文稿、电子邮件和社交帖子",
      step_two_description: "将其放入您的网络研讨会演示文稿、电子邮件、社交帖子...与合作伙伴互动的任何地方。",
      step_three_header: "添加解释性文本",
      step_three_description: "添加解释性文本，帮助合作伙伴理解为什么要分享这些链接和二维码。",
      step_three_tip: "不要忘记粘贴您的链接和/或二维码！",
      sales_example_one: "这是销售:partnerName的最简单方式。点击开始！",
      sales_example_two: "点击立即个性化并分享我们最新的内容体验。",
      sales_example_three: "点击即可立即开始销售:partnerName",
      just_a_moment: "稍等片刻",
      thats_strange: "这很奇怪...",
      invalid_magic_link: "该魔法链接无效。请重新点击魔法链接按钮或粘贴URL",
      oops: "哎呀！",
      magic_link_used: "魔法链接只能使用一次，该链接已被使用。请重新输入您的电子邮件地址以接收新链接。",
      get_new_magic_link: "获取新魔法链接！",
      new_magic_link_sent: "您的魔法链接已过期，因此我们已发送给您新的链接。",
      magic_link_check_email: "检查您的电子邮件并点击登录",
      cobrand_our_website: "个性化我们的网站",
      required_field: "此字段是必填的。",
      sales_example_description: "以下是一些开始的示例：",
      confirm: "确认",
      your: "你的",
      you_email_com: "nǐ",
      upload_image: "上传图片...",
      demo_phone_number: "+86 10 5551 2121",
    },
    nl: {
      first_name: "Voornaam",
      last_name: "Achternaam",
      phone_number: "Telefoonnummer",
      logo: "Logo",
      personal_photo: "Persoonlijke foto",
      personal_message: "Persoonlijk bericht",
      call_to_action_button: "Knop met hyperlink",
      button_text: "Knoptekst",
      none: "Geen",
      preset: "Standaardinstelling",
      custom: "Aangepast",
      preview: "Voorbeeld",
      share: "Delen",
      personalize: "Personaliseren",
      view: "Bekijk",
      complete_your_profile: "Vul je profiel in",
      personal_details: "Contactgegevens",
      customize_content_experience: "Personaliseren van inhoudservaring",
      share_on_whatsapp: "Deel op WhatsApp",
      language: "Taal",
      ai_description: "Onze AI-assistent schrijft iets speciaal voor jou! Kopieer, plak, bewerk en deel.",
      ai_regenerate: "Probeer iets anders",
      copy_text_create_post: "Kopieer en plak op Facebook",
      copy_link_to_content_experience: "Kopieer link naar jouw inhoudservaring",
      email: "E-mail",
      qr_code: "QR-code",
      link: "Link",
      my_experiences: "Mijn ervaringen",
      my_profile: "Mijn profiel",
      about_my_business: "Over mijn bedrijf",
      i_am_a_travel_advisor: "Ik ben een reisadviseur/reisbureau of een vergelijkbare derde partij verkoop van reizen",
      host_agency: "Gastbedrijf",
      agency_association_consortium: "Bureau-associatie / Consortium",
      booking_email: "E-mail gebruikt voor boekingen",
      booking_phone_number: "Telefoonnummer gebruikt voor boekingen",
      iata_used_for_booking: "IATA gebruikt voor boekingen",
      clia_used_for_booking: "CLIA gebruikt voor boekingen",
      company_name: "Bedrijfsnaam",
      business_languages: "Talen waarmee ik zaken doe",
      update: "Bijwerken",
      saving: "Bezig met opslaan",
      saved: "Opgeslagen",
      logout: "Uitloggen",
      cobrand_and_share: "Personaliseer en deel",
      your_content_experiences: "Jouw inhoudservaringen",
      your_experiences: "Jouw ervaringen",
      new_experiences: "Nieuwe ervaringen",
      all_content_experiences: "Alle inhoudservaringen",
      search: "Zoeken",
      experiences: "Ervaringen",
      cobranded_website: "Gepersonaliseerde website",
      about: "Over",
      your_partnerName_content_experiences: "Jouw :partnerName inhoudservaringen",
      content_experiences_description:
        "Inhoudservaringen stellen je in staat om snel inspirerende inhoud op een zeer persoonlijke manier te delen. **Klik op een inhoudservaring hieronder om te beginnen.**",
      cobrand_the_partnerName_website: "Personaliseer de :partnerName website",
      cobrand_website_url_description:
        "Hier is jouw gepersonaliseerde :partnerName website. Deel deze link met klanten - we hebben de site speciaal voor jou aangepast.",
      copy_link: "Kopieer link",
      copied: "Gekopieerd",
      cobrand_website_any_url_description:
        "Het wordt nog beter! Je kunt elke pagina op de :partnerName website personaliseren. Voer gewoon een URL van :partnerName in om je gepersonaliseerde link te krijgen.",
      cobrand: "Personaliseren",
      contact: "Contact",
      for_travel_brands: "Voor reismerken",
      recommend: "Aanbevelen",
      recommend_us_to_suppliers: "Raad ons aan bij je favoriete partners",
      recommend_us_description:
        "Wil je niet dat al je partners marketing zo gemakkelijk maken? Laat ze weten hoe ze eenvoudig inhoud kunnen creëren die jouw marketing versterkt. Klik op de knop en laat ons een e-mail voor je schrijven!",
      cancel: "Annuleren",
      copy_and_paste_in_email: "Kopieer (en plak in een e-mail)",
      pagination: ":itemsFrom - :itemsTo van :itemsTotal items.",
      choose_a_content_experience: "Kies een inhoudservaring",
      sign_in: "Inloggen",
      get_started: "Begin",
      sign_in_to_account: "Log in op je account",
      sign_in_description: "Voer je e-mailadres in en we sturen je een link die je automatisch inlogt.",
      email_address: "E-mailadres",
      no_account: "Heb je geen account?",
      sign_up_title: "Meld je aan om gepersonaliseerde inhoud te delen",
      sign_up_description: "Binnen enkele seconden kun je onze inhoudservaringen personaliseren en delen om je marketing naar een hoger niveau te tillen.",
      create_account: "Maak een account aan",
      personalize_your_content_experience: "Personaliseer deze inhoudservaring",
      invitation_description:
        "We hebben deze inhoudservaring ontworpen om je marketing te verbeteren en je te helpen meer te verdienen. Het is gemakkelijk te personaliseren—voer gewoon je e-mail in om te beginnen!",
      enter_email_legal:
        "Door uw e-mailadres in te voeren, gaat u akkoord met de [servicevoorwaarden](https://approachguides.com/terms/) en het [privacybeleid](https://approachguides.com/privacy/)",
      and: "en",
      magic_link_email_sent_title: "Controleer je e-mail!",
      magic_link_email_sent_description: "Klik op de magische link die we naar **:userEmail** hebben gestuurd",
      magic_link_legal:
        "Door op de magische link te klikken, gaat u akkoord met de [servicevoorwaarden](https://approachguides.com/terms/) en het [privacybeleid](https://approachguides.com/privacy/)",
      preview_description: "Tip: Gebruik een incognitovenster om de weergave van je klanten te bekijken.",
      name: "Naam",
      logo_personal_photo: "Logo en persoonlijke foto",
      location: "Locatie",
      consortium: "Consortium",
      booking_email_phone: "Boekings-e-mail en telefoon",
      home: "Home",
      update_profile: "Profiel bijwerken",
      profile_instructions: "Bevestig of vul de gemarkeerde velden in.",
      select: "Selecteer",
      country: "Land",
      state_region: "Staat / Regio",
      homepage_title: "Til je marketing en verkoop naar een hoger niveau",
      homepage_subtitle: "met gratis gepersonaliseerde inhoudservaringen",
      share_qr_code_description: "Download de QR-code of maak een screenshot voor snelle delen.",
      download_qr_code: "Download QR-code",
      bilbert_email_start: "Ik maak een e-mail speciaal voor jou! Geef me een paar seconden",
      bilbert_email_end: "Klaar! Klik op de knop hieronder om de tekst te kopiëren, en plak deze vervolgens in een e-mail en stuur!",
      ai_robot: "AI Assistent",
      sign_up: "Aanmelden",
      already_signed_up: "Al aangemeld?",
      cookies_description: "We gebruiken cookies om je ervaring te verbeteren.",
      learn_more: "Leer meer",
      accept: "Accepteren",
      hooray: "Hoera!",
      experience_creation_description: "Geef ons een moment terwijl we je gepersonaliseerde ervaring creëren...",
      share_link_everywhere: "Deel je link overal",
      sales_links_header: "Links om met partners te delen",
      sales_links_description:
        "Bijwonen van een vergadering of evenement, een webinar houden, een e-mail sturen? Dit is alles wat je nodig hebt om partners uit te nodigen om je inhoudservaringen te personaliseren en te delen.",
      step_one_header: "Kopieer de uitnodigingslink of download de QR-code",
      step_one_description:
        "Kopieer de uitnodigingslink of download de QR-code voor alle :partnerName inhoudservaringen, of kies een specifieke inhoudservaring.",
      step_one_tip: "Vergeet niet om je QR-code te **testen** voordat je deze deelt!",
      copy_invite_link: "Kopieer uitnodigingslink",
      copied_to_clipboard: "Gekopieerd naar het klembord",
      or_choose_content_experience: "Of kies een specifieke inhoudservaring:",
      step_two_header: "Voeg het toe aan je webinarpresentatie, e-mail en social media posts",
      step_two_description: "Voeg het toe aan je webinarpresentatie, e-mail, social media post... waar je ook met handelspartners omgaat.",
      step_three_header: "Voeg een verklarende tekst toe",
      step_three_description: "Voeg een verklarende tekst toe om je partners te helpen begrijpen waarom je de links en QR-codes deelt.",
      step_three_tip: "Vergeet niet je link en/of QR-code in te voegen!",
      sales_example_one: "Het is de makkelijkste manier om :partnerName te verkopen. Klik om te beginnen!",
      sales_example_two: "Klik om onze laatste inhoudservaring direct te personaliseren en te delen.",
      sales_example_three: "Klik om onmiddellijk te beginnen met verkopen :partnerName",
      just_a_moment: "Een momentje",
      thats_strange: "Dat is vreemd...",
      invalid_magic_link: "Deze magische link is ongeldig. Klik opnieuw op de magische linkknop of plak de URL",
      oops: "Oeps!",
      magic_link_used:
        "Magische links zijn voor éénmalig gebruik en deze is al gebruikt. Voer opnieuw je e-mailadres in om een nieuwe magische link te ontvangen.",
      get_new_magic_link: "Ontvang je nieuwe magische link!",
      new_magic_link_sent: "Je magische link is verlopen, we hebben een nieuwe voor je gestuurd.",
      magic_link_check_email: "Controleer je e-mail en klik om in te loggen",
      cobrand_our_website: "Personaliseer onze website",
      required_field: "Dit veld is verplicht.",
      sales_example_description: "Hier zijn enkele voorbeelden om je op weg te helpen:",
      confirm: "Bevestigen",
      your: "Jouw",
      you_email_com: "jij",
      upload_image: "Afbeelding uploaden...",
      demo_phone_number: "+31 20 555 1212",
    },
    sv: {
      first_name: "Förnamn",
      last_name: "Efternamn",
      phone_number: "Telefonnummer",
      logo: "Logotyp",
      personal_photo: "Personligt foto",
      personal_message: "Personligt meddelande",
      call_to_action_button: "Knapp med hyperlänk",
      button_text: "Knapptext",
      none: "Ingen",
      preset: "Förinställt",
      custom: "Anpassat",
      preview: "Förhandsgranska",
      share: "Dela",
      personalize: "Anpassa",
      view: "Visa",
      complete_your_profile: "Komplett din profil",
      personal_details: "Kontaktinformation",
      customize_content_experience: "Anpassa innehållsupplevelse",
      share_on_whatsapp: "Dela på WhatsApp",
      language: "Språk",
      ai_description: "Vår AI-assistent skriver något speciellt för dig! Kopiera, klistra in, redigera och dela.",
      ai_regenerate: "Försök något annat",
      copy_text_create_post: "Kopiera och klistra in på Facebook",
      copy_link_to_content_experience: "Kopiera länk till din innehållsupplevelse",
      email: "E-post",
      qr_code: "QR-kod",
      link: "Länk",
      my_experiences: "Mina upplevelser",
      my_profile: "Min profil",
      about_my_business: "Om mitt företag",
      i_am_a_travel_advisor: "Jag är en rese rådgivare/agent eller en liknande tredje part säljare av resor",
      host_agency: "Värdbyrå",
      agency_association_consortium: "Byråassociation / Konsortium",
      booking_email: "E-post som används för bokning",
      booking_phone_number: "Telefonnummer som används för bokning",
      iata_used_for_booking: "IATA som används för bokning",
      clia_used_for_booking: "CLIA som används för bokning",
      company_name: "Företagsnamn",
      business_languages: "Språk jag bedriver verksamhet på",
      update: "Uppdatera",
      saving: "Sparar",
      saved: "Sparad",
      logout: "Logga ut",
      cobrand_and_share: "Anpassa och dela",
      your_content_experiences: "Dina innehållsupplevelser",
      your_experiences: "Dina upplevelser",
      new_experiences: "Nya upplevelser",
      all_content_experiences: "Alla innehållsupplevelser",
      search: "Sök",
      experiences: "Upplevelser",
      cobranded_website: "Personlig webbplats",
      about: "Om",
      your_partnerName_content_experiences: "Dina :partnerName innehållsupplevelser",
      content_experiences_description:
        "Innehållsupplevelser gör att du snabbt kan dela inspirerande innehåll på ett mycket personligt sätt. **Klicka på en innehållsupplevelse nedan för att komma igång.**",
      cobrand_the_partnerName_website: "Anpassa :partnerName webbplats",
      cobrand_website_url_description: "Här är din personliga :partnerName webbplats. Dela denna länk med kunder - vi har omvandlat webbplatsen just för dig.",
      copy_link: "Kopiera länk",
      copied: "Kopierad",
      cobrand_website_any_url_description:
        "Det blir ännu bättre! Du kan anpassa vilken som helst av sidorna på :partnerName webbplats. Ange helt enkelt vilken URL från :partnerName för att få din personliga länk.",
      cobrand: "Anpassa",
      contact: "Kontakt",
      for_travel_brands: "För resevarumärken",
      recommend: "Rekommendera",
      recommend_us_to_suppliers: "Rekommendera oss till dina favoritpartners",
      recommend_us_description:
        "Önskar du inte att alla dina partners gör marknadsföring så enkelt? Låt dem veta hur de enkelt kan skapa innehåll som stärker din marknadsföring. Klicka på knappen och låt oss skapa ett e-postmeddelande för dig!",
      cancel: "Avbryt",
      copy_and_paste_in_email: "Kopiera (och klistra in i ett e-postmeddelande)",
      pagination: ":itemsFrom - :itemsTo av :itemsTotal artiklar.",
      choose_a_content_experience: "Välj en innehållsupplevelse",
      sign_in: "Logga in",
      get_started: "Kom igång",
      sign_in_to_account: "Logga in på ditt konto",
      sign_in_description: "Ange din e-postadress och vi skickar en länk som loggar in dig automatiskt.",
      email_address: "E-postadress",
      no_account: "Har du inget konto?",
      sign_up_title: "Registrera dig för att börja dela anpassat innehåll",
      sign_up_description: "På några sekunder kan du anpassa och dela våra innehållsupplevelser för att ta din marknadsföring till nästa nivå.",
      create_account: "Skapa konto",
      personalize_your_content_experience: "Anpassa denna innehållsupplevelse",
      invitation_description:
        "Vi har skapat denna innehållsupplevelse för att stärka din marknadsföring och hjälpa dig att tjäna mer. Det är lätt att anpassa—ange bara din e-postadress för att komma igång!",
      enter_email_legal:
        "Genom att ange din e-postadress godkänner du [användarvillkoren](https://approachguides.com/terms/) och [integritetspolicyn](https://approachguides.com/privacy/)",
      and: "och",
      magic_link_email_sent_title: "Kontrollera din e-post!",
      magic_link_email_sent_description: "Klicka på den magiska länken vi skickade till **:userEmail**",
      magic_link_legal:
        "Genom att klicka på den magiska länken godkänner du [användarvillkoren](https://approachguides.com/terms/) och [integritetspolicyn](https://approachguides.com/privacy/)",
      preview_description: "Tips: Använd en inkognitoflik för att visa hur det ser ut för dina kunder.",
      name: "Namn",
      logo_personal_photo: "Logo och personlig foto",
      location: "Plats",
      consortium: "Konsortium",
      booking_email_phone: "Boknings-e-post och telefon",
      home: "Hem",
      update_profile: "Uppdatera profil",
      profile_instructions: "Vänligen bekräfta eller fyll i de markerade fälten.",
      select: "Välj",
      country: "Land",
      state_region: "Stat / Region",
      homepage_title: "Ta din marknadsföring och försäljning till nästa nivå",
      homepage_subtitle: "med gratis anpassade innehållsupplevelser",
      share_qr_code_description: "Ladda ner QR-koden eller ta en skärmdump för snabbare delning.",
      download_qr_code: "Ladda ner QR-kod",
      bilbert_email_start: "Jag skapar ett e-postmeddelande bara för dig! Ge mig några sekunder",
      bilbert_email_end: "Klart! Klicka på knappen nedan för att kopiera texten, och klistra sedan in den i ett e-postmeddelande och skicka!",
      ai_robot: "AI Assistent",
      sign_up: "Registrera dig",
      already_signed_up: "Redan registrerad?",
      cookies_description: "Vi använder cookies för att förbättra din upplevelse.",
      learn_more: "Läs mer",
      accept: "Acceptera",
      hooray: "Hurra!",
      experience_creation_description: "Ge oss ett ögonblick medan vi skapar din personliga upplevelse...",
      share_link_everywhere: "Dela din länk överallt",
      sales_links_header: "Länkar att dela med partners",
      sales_links_description:
        "Deltar du i ett möte eller evenemang, håller ett webbseminarium, eller skickar ut ett e-postmeddelande? Här är allt du behöver för att bjuda in partners att anpassa och dela dina innehållsupplevelser.",
      step_one_header: "Kopiera inbjudningslänken eller ladda ner QR-koden",
      step_one_description:
        "Kopiera inbjudningslänken eller ladda ner QR-koden för alla :partnerName innehållsupplevelser, eller välj en specifik innehållsupplevelse.",
      step_one_tip: "Glöm inte att **testa** din QR-kod innan du delar den!",
      copy_invite_link: "Kopiera inbjudningslänk",
      copied_to_clipboard: "Kopierad till urklipp",
      or_choose_content_experience: "Eller välj en specifik innehållsupplevelse:",
      step_two_header: "Lägg till det i din webinarpresentation, e-post och sociala inlägg",
      step_two_description: "Lägg till det i din webinarpresentation, e-post, sociala inlägg... var du än interagerar med handelspartners.",
      step_three_header: "Lägg till förklarande text",
      step_three_description: "Lägg till förklarande text för att hjälpa partners att förstå varför du delar länkar och QR-koder.",
      step_three_tip: "Glöm inte att klistra in din länk och/eller QR-kod!",
      sales_example_one: "Det är det enklaste sättet att sälja :partnerName. Klicka för att komma igång!",
      sales_example_two: "Klicka för att direkt personligt anpassa och dela vår senaste innehållsupplevelse.",
      sales_example_three: "Klicka för att omedelbart börja sälja :partnerName",
      just_a_moment: "Ett ögonblick",
      thats_strange: "Det är konstigt...",
      invalid_magic_link: "Denna magiska länk är ogiltig. Vänligen klicka på den magiska länken igen eller klistra in URL:en",
      oops: "Oj!",
      magic_link_used: "Magiska länkar är för engångsbruk och denna har redan använts. Vänligen ange din e-postadress igen för att få en ny magisk länk.",
      get_new_magic_link: "Få din nya magiska länk!",
      new_magic_link_sent: "Din magiska länk har gått ut så vi har skickat en ny till dig.",
      magic_link_check_email: "Kontrollera din e-post och klicka för att logga in",
      cobrand_our_website: "Personalisera vår webbplats",
      required_field: "Detta fält är obligatoriskt.",
      sales_example_description: "Här är några exempel för att komma igång:",
      confirm: "Bekräfta",
      your: "Ditt",
      you_email_com: "du",
      upload_image: "Ladda upp bild...",
      demo_phone_number: "+46 8 555 1212",
    },
    el: {
      first_name: "Όνομα",
      last_name: "Επώνυμο",
      phone_number: "Αριθμός τηλεφώνου",
      logo: "Λογότυπο",
      personal_photo: "Προσωπική φωτογραφία",
      personal_message: "Προσωπικό μήνυμα",
      call_to_action_button: "Κουμπί με υπερσύνδεσμο",
      button_text: "Κείμενο κουμπιού",
      none: "Κανένα",
      preset: "Προεπιλογή",
      custom: "Προσαρμοσμένο",
      preview: "Προεπισκόπηση",
      share: "Κοινοποίηση",
      personalize: "Προσαρμογή",
      view: "Προβολή",
      complete_your_profile: "Ολοκληρώστε το προφίλ σας",
      personal_details: "Στοιχεία επικοινωνίας",
      customize_content_experience: "Προσαρμογή εμπειρίας περιεχομένου",
      share_on_whatsapp: "Κοινοποίηση στο WhatsApp",
      language: "Γλώσσα",
      ai_description: "Ο Βοηθός AI μας γράφει κάτι μόνο για εσάς! Απλώς αντιγράψτε, επικολλήστε, επεξεργαστείτε και κοινοποιήστε.",
      ai_regenerate: "Δοκιμάστε κάτι διαφορετικό",
      copy_text_create_post: "Αντιγράψτε και επικολλήστε στο Facebook",
      copy_link_to_content_experience: "Αντιγράψτε τον σύνδεσμο στην εμπειρία περιεχομένου σας",
      email: "Ηλεκτρονικό ταχυδρομείο",
      qr_code: "Κωδικός QR",
      link: "Σύνδεσμος",
      my_experiences: "Οι εμπειρίες μου",
      my_profile: "Το προφίλ μου",
      about_my_business: "Σχετικά με την επιχείρησή μου",
      i_am_a_travel_advisor: "Είμαι σύμβουλος ταξιδιών/γραφείο ταξιδίων ή παρόμοιος τρίτος πωλητής ταξιδιών",
      host_agency: "Οργανωτής",
      agency_association_consortium: "Σύνδεσμος / Κοινοπραξία γραφείων ταξιδίων",
      booking_email: "Ηλεκτρονικό ταχυδρομείο για κρατήσεις",
      booking_phone_number: "Τηλέφωνο για κρατήσεις",
      iata_used_for_booking: "IATA που χρησιμοποιείται για κρατήσεις",
      clia_used_for_booking: "CLIA που χρησιμοποιείται για κρατήσεις",
      company_name: "Όνομα εταιρείας",
      business_languages: "Γλώσσες στις οποίες κάνω δουλειές",
      update: "Ενημέρωση",
      saving: "Αποθήκευση",
      saved: "Αποθηκευμένο",
      logout: "Αποσύνδεση",
      cobrand_and_share: "Προσαρμογή και κοινοποίηση",
      your_content_experiences: "Οι εμπειρίες περιεχομένου σας",
      your_experiences: "Οι εμπειρίες σας",
      new_experiences: "Νέες εμπειρίες",
      all_content_experiences: "Όλες οι εμπειρίες περιεχομένου",
      search: "Αναζήτηση",
      experiences: "Εμπειρίες",
      cobranded_website: "Προσωποποιημένος ιστότοπος",
      about: "Σχετικά",
      your_partnerName_content_experiences: "Οι εμπειρίες περιεχομένου :partnerName",
      content_experiences_description:
        "Οι εμπειρίες περιεχομένου σας επιτρέπουν να μοιράζεστε γρήγορα εμπνευσμένο περιεχόμενο με έναν εξαιρετικά προσωπικό τρόπο. **Κάντε κλικ σε μια εμπειρία περιεχομένου παρακάτω για να ξεκινήσετε.**",
      cobrand_the_partnerName_website: "Προσαρμογή του ιστότοπου :partnerName",
      cobrand_website_url_description:
        "Εδώ είναι ο προσαρμοσμένος ιστότοπος :partnerName. Μοιραστείτε αυτόν τον σύνδεσμο με τους πελάτες – έχουμε μεταμορφώσει τον ιστότοπο μόνο για εσάς.",
      copy_link: "Αντιγραφή συνδέσμου",
      copied: "Αντιγράφηκε",
      cobrand_website_any_url_description:
        "Γίνεται ακόμα καλύτερο! Μπορείτε να προσαρμόσετε οποιαδήποτε σελίδα στον ιστότοπο :partnerName. Απλώς εισαγάγετε οποιονδήποτε σύνδεσμο URL από το :partnerName για να αποκτήσετε τον προσαρμοσμένο σύνδεσμο.",
      cobrand: "Προσαρμογή",
      contact: "Επικοινωνία",
      for_travel_brands: "Για ταξιδιωτικά εμπορικά σήματα",
      recommend: "Συνιστώ",
      recommend_us_to_suppliers: "Συστήστε μας στους αγαπημένους σας συνεργάτες",
      recommend_us_description:
        "Δεν θα θέλατε όλοι οι συνεργάτες σας να κάνουν το μάρκετινγκ τόσο εύκολο; Ενημερώστε τους ότι μπορούν να δημιουργήσουν εύκολα περιεχόμενο που ενισχύει το μάρκετινγκ σας. Κάντε κλικ στο κουμπί και ας δημιουργήσουμε μια e-mail για εσάς!",
      cancel: "Ακύρωση",
      copy_and_paste_in_email: "Αντιγράψτε (και επικολλήστε σε ένα e-mail)",
      pagination: ":itemsFrom - :itemsTo από :itemsTotal στοιχεία.",
      choose_a_content_experience: "Επιλέξτε μια εμπειρία περιεχομένου",
      sign_in: "Συνδεθείτε",
      get_started: "Ξεκινήστε",
      sign_in_to_account: "Συνδεθείτε στο λογαριασμό σας",
      sign_in_description: "Εισαγάγετε το email σας και θα σας στείλουμε έναν σύνδεσμο που θα σας συνδέσει αυτόματα.",
      email_address: "Διεύθυνση ηλεκτρονικού ταχυδρομείου",
      no_account: "Δεν έχετε λογαριασμό;",
      sign_up_title: "Εγγραφείτε για να ξεκινήσετε να μοιράζεστε εξατομικευμένο περιεχόμενο",
      sign_up_description:
        "Σε λίγα δευτερόλεπτα, μπορείτε να εξατομικεύσετε και να μοιραστείτε τις εμπειρίες περιεχομένου μας για να ενισχύσετε το μάρκετινγκ σας.",
      create_account: "Δημιουργία λογαριασμού",
      personalize_your_content_experience: "Προσαρμόστε αυτή την εμπειρία περιεχομένου",
      invitation_description:
        "Έχουμε δημιουργήσει αυτή την εμπειρία περιεχομένου για να ενισχύσουμε το μάρκετινγκ σας και να σας βοηθήσουμε να κερδίσετε περισσότερα. Είναι εύκολο να την προσαρμόσετε—απλώς εισαγάγετε το email σας για να ξεκινήσετε!",
      enter_email_legal:
        "Εισάγοντας τη διεύθυνση email σας, συμφωνείτε με τους [όρους υπηρεσίας](https://approachguides.com/terms/) και την [πολιτική απορρήτου](https://approachguides.com/privacy/)",
      and: "και",
      magic_link_email_sent_title: "Ελέγξτε το email σας!",
      magic_link_email_sent_description: "Κάντε κλικ στον μαγικό σύνδεσμο που σας στείλαμε στο **:userEmail**",
      magic_link_legal:
        "Κάνοντας κλικ στον μαγικό σύνδεσμο, συμφωνείτε με τους [όρους υπηρεσίας](https://approachguides.com/terms/) και την [πολιτική απορρήτου](https://approachguides.com/privacy/)",
      preview_description: "Συμβουλή: Χρησιμοποιήστε ένα παράθυρο ανώνυμης περιήγησης για να δείτε την προβολή των πελατών σας.",
      name: "Όνομα",
      logo_personal_photo: "Λογότυπο και προσωπική φωτογραφία",
      location: "Τοποθεσία",
      consortium: "Συνεταιρισμός",
      booking_email_phone: "Ηλεκτρονικό ταχυδρομείο και τηλέφωνο για κρατήσεις",
      home: "Αρχική",
      update_profile: "Ενημέρωση προφίλ",
      profile_instructions: "Παρακαλώ επιβεβαιώστε ή συμπληρώστε τα επισημασμένα πεδία.",
      select: "Επιλέξτε",
      country: "Χώρα",
      state_region: "Πολιτεία / Περιοχή",
      homepage_title: "Αναβαθμίστε το μάρκετινγκ και τις πωλήσεις σας",
      homepage_subtitle: "με δωρεάν εξατομικευμένες εμπειρίες περιεχομένου",
      share_qr_code_description: "Κατεβάστε τον QR κωδικό ή κάντε screenshot για γρηγορότερη κοινοποίηση.",
      download_qr_code: "Κατέβασμα QR κωδικού",
      bilbert_email_start: "Δημιουργώ ένα email μόνο για εσάς! Δώστε μου λίγα δευτερόλεπτα",
      bilbert_email_end: "Έγινε! Κάντε κλικ στο κουμπί παρακάτω για να αντιγράψετε το κείμενο, και μετά επικολλήστε το σε ένα email και στείλτε το!",
      ai_robot: "Βοηθός AI",
      sign_up: "Εγγραφή",
      already_signed_up: "Ήδη εγγεγραμμένος;",
      cookies_description: "Χρησιμοποιούμε cookies για να βελτιώσουμε την εμπειρία σας.",
      learn_more: "Μάθετε περισσότερα",
      accept: "Αποδοχή",
      hooray: "Ευτυχώς!",
      experience_creation_description: "Δώστε μας ένα λεπτό καθώς δημιουργούμε την εξατομικευμένη σας εμπειρία...",
      share_link_everywhere: "Μοιραστείτε το σύνδεσμο παντού",
      sales_links_header: "Σύνδεσμοι για κοινοποίηση με συνεργάτες",
      sales_links_description:
        "Συμμετέχετε σε μια συνάντηση ή εκδήλωση, πραγματοποιείτε ένα webinar ή στέλνετε ένα email; Αυτό είναι ό,τι χρειάζεστε για να προσκαλέσετε συνεργάτες να εξατομικεύσουν και να μοιραστούν τις εμπειρίες περιεχομένου σας.",
      step_one_header: "Αντιγράψτε το σύνδεσμο πρόσκλησης ή κατεβάστε τον QR κωδικό",
      step_one_description:
        "Αντιγράψτε το σύνδεσμο πρόσκλησης ή κατεβάστε τον QR κωδικό για όλες τις εμπειρίες περιεχομένου :partnerName ή επιλέξτε μια συγκεκριμένη εμπειρία περιεχομένου.",
      step_one_tip: "Μην ξεχάσετε να **δοκιμάσετε** τον QR κωδικό πριν τον μοιραστείτε!",
      copy_invite_link: "Αντιγράψτε το σύνδεσμο πρόσκλησης",
      copied_to_clipboard: "Αντιγράφηκε στο πρόχειρο",
      or_choose_content_experience: "Ή επιλέξτε μια συγκεκριμένη εμπειρία περιεχομένου:",
      step_two_header: "Τοποθετήστε το στην παρουσίαση του webinar, στο email και στα κοινωνικά μέσα",
      step_two_description: "Τοποθετήστε το στην παρουσίαση του webinar, στο email, στην ανάρτηση στα κοινωνικά μέσα... όπου αλληλεπιδράτε με συνεργάτες.",
      step_three_header: "Προσθέστε εξηγητικό κείμενο",
      step_three_description:
        "Προσθέστε εξηγητικό κείμενο για να βοηθήσετε τους συνεργάτες να κατανοήσουν γιατί μοιράζεστε τους συνδέσμους και τους QR κωδικούς.",
      step_three_tip: "Μην ξεχάσετε να επικολλήσετε το σύνδεσμο και/ή QR κωδικό σας!",
      sales_example_one: "Είναι ο πιο εύκολος τρόπος να πουλήσετε :partnerName. Κάντε κλικ για να ξεκινήσετε!",
      sales_example_two: "Κάντε κλικ για να εξατομικεύσετε και να μοιραστείτε άμεσα την τελευταία εμπειρία περιεχομένου μας.",
      sales_example_three: "Κάντε κλικ για να ξεκινήσετε να πουλάτε άμεσα :partnerName",
      just_a_moment: "Μόλις ένα λεπτό",
      thats_strange: "Αυτό είναι περίεργο...",
      invalid_magic_link: "Αυτός ο μαγικός σύνδεσμος δεν είναι έγκυρος. Παρακαλώ κάντε κλικ ξανά στον μαγικό σύνδεσμο ή επικολλήστε τη σχετική URL",
      oops: "Ουπς!",
      magic_link_used:
        "Οι μαγικοί σύνδεσμοι είναι για μία μόνο χρήση και αυτός έχει ήδη χρησιμοποιηθεί. Εισάγετε ξανά το email σας για να λάβετε νέο μαγικό σύνδεσμο.",
      get_new_magic_link: "Λάβετε το νέο μαγικό σύνδεσμο!",
      new_magic_link_sent: "Ο μαγικός σας σύνδεσμος έληξε, οπότε σας στείλαμε έναν νέο.",
      magic_link_check_email: "Ελέγξτε το email σας και κάντε κλικ για να συνδεθείτε",
      cobrand_our_website: "Προσαρμόστε τον ιστότοπό μας",
      required_field: "Αυτό το πεδίο είναι υποχρεωτικό.",
      sales_example_description: "Ορίστε μερικά παραδείγματα για να ξεκινήσετε:",
      confirm: "Επιβεβαιώστε",
      your: "Σου",
      you_email_com: "εσύ",
      upload_image: "Ανέβασμα εικόνας...",
      demo_phone_number: "+30 21 555 1212",
    },
    da: {
      first_name: "Fornavn",
      last_name: "Efternavn",
      phone_number: "Telefonnummer",
      logo: "Logo",
      personal_photo: "Personligt billede",
      personal_message: "Personlig besked",
      call_to_action_button: "Knap med hyperlink",
      button_text: "Knaptekst",
      none: "Ingen",
      preset: "Forudindstillet",
      custom: "Brugerdefineret",
      preview: "Forhåndsvisning",
      share: "Del",
      personalize: "Personliggør",
      view: "Vis",
      complete_your_profile: "Fuldfør din profil",
      personal_details: "Kontaktinformation",
      customize_content_experience: "Tilpas indholdoplevelse",
      share_on_whatsapp: "Del på WhatsApp",
      language: "Sprog",
      ai_description: "Vores AI-assistent skriver noget specielt til dig! Kopiér, indsæt, rediger og del.",
      ai_regenerate: "Prøv noget andet",
      copy_text_create_post: "Kopiér og indsæt på Facebook",
      copy_link_to_content_experience: "Kopiér linket til din indholdoplevelse",
      email: "E-mail",
      qr_code: "QR-kode",
      link: "Link",
      my_experiences: "Mine oplevelser",
      my_profile: "Min profil",
      about_my_business: "Om min virksomhed",
      i_am_a_travel_advisor: "Jeg er en rejserådgiver/rejsebureau eller en lignende tredjepart sælger af rejser",
      host_agency: "Værtsagentur",
      agency_association_consortium: "Agenturforening / Konsortium",
      booking_email: "E-mail brugt til booking",
      booking_phone_number: "Telefonnummer brugt til booking",
      iata_used_for_booking: "IATA brugt til booking",
      clia_used_for_booking: "CLIA brugt til booking",
      company_name: "Virksomhedsnavn",
      business_languages: "Sprog jeg driver forretning på",
      update: "Opdater",
      saving: "Gemmer",
      saved: "Gemt",
      logout: "Log ud",
      cobrand_and_share: "Personliggør og del",
      your_content_experiences: "Dine indholdoplevelser",
      your_experiences: "Dine oplevelser",
      new_experiences: "Nye oplevelser",
      all_content_experiences: "Alle indholdoplevelser",
      search: "Søg",
      experiences: "Oplevelser",
      cobranded_website: "Personliggjort website",
      about: "Om",
      your_partnerName_content_experiences: "Dine :partnerName indholdoplevelser",
      content_experiences_description:
        "Indholdoplevelser gør det muligt at dele inspirerende indhold på en meget personlig måde. **Klik på en indholdoplevelse nedenfor for at komme i gang.**",
      cobrand_the_partnerName_website: "Personliggør :partnerName website",
      cobrand_website_url_description: "Her er din personlige :partnerName website. Del dette link med kunder - vi har transformeret siden kun for dig.",
      copy_link: "Kopiér link",
      copied: "Kopieret",
      cobrand_website_any_url_description:
        "Det bliver endnu bedre! Du kan personliggøre enhver side på :partnerName website. Indtast bare en hvilken som helst :partnerName URL for at få dit personlige link.",
      cobrand: "Personliggør",
      contact: "Kontakt",
      for_travel_brands: "For rejsebrands",
      recommend: "Anbefal",
      recommend_us_to_suppliers: "Anbefal os til dine foretrukne partnere",
      recommend_us_description:
        "Vil du ikke ønske, at alle dine partnere gjorde marketing så nemt? Lad dem vide, hvordan de nemt kan skabe indhold, der styrker din marketing. Klik på knappen, og lad os udarbejde en e-mail for dig!",
      cancel: "Annuller",
      copy_and_paste_in_email: "Kopiér (og indsæt i en e-mail)",
      pagination: ":itemsFrom - :itemsTo af :itemsTotal emner.",
      choose_a_content_experience: "Vælg en indholdoplevelse",
      sign_in: "Log ind",
      get_started: "Kom i gang",
      sign_in_to_account: "Log ind på din konto",
      sign_in_description: "Indtast din e-mail, og vi sender dig et link, der logger dig ind automatisk.",
      email_address: "E-mail adresse",
      no_account: "Har du ikke en konto?",
      sign_up_title: "Tilmeld dig for at begynde at dele personliggjort indhold",
      sign_up_description: "På få sekunder kan du personliggøre og dele vores indholdoplevelser for at tage din marketing til næste niveau.",
      create_account: "Opret konto",
      personalize_your_content_experience: "Personliggør denne indholdoplevelse",
      invitation_description:
        "Vi har skabt denne indholdoplevelse for at løfte din marketing og hjælpe dig med at tjene mere. Det er let at personliggøre—indtast blot din e-mail for at komme i gang!",
      enter_email_legal:
        "Ved at indtaste din e-mailadresse accepterer du vores [servicevilkår](https://approachguides.com/terms/) og [privatlivspolitik](https://approachguides.com/privacy/)",
      and: "og",
      magic_link_email_sent_title: "Tjek din e-mail!",
      magic_link_email_sent_description: "Klik på den magiske link, vi sendte til **:userEmail**",
      magic_link_legal:
        "Ved at klikke på den magiske link accepterer du vores [servicevilkår](https://approachguides.com/terms/) og [privatlivspolitik](https://approachguides.com/privacy/)",
      preview_description: "Tip: Brug et inkognitovindue for at se, hvordan dine kunder ser det.",
      name: "Navn",
      logo_personal_photo: "Logo & Personlig foto",
      location: "Placering",
      consortium: "Konsortium",
      booking_email_phone: "Booking e-mail & telefon",
      home: "Hjem",
      update_profile: "Opdater profil",
      profile_instructions: "Bekræft eller udfyld de markerede felter.",
      select: "Vælg",
      country: "Land",
      state_region: "Stat / Region",
      homepage_title: "Tag din marketing og salg til næste niveau",
      homepage_subtitle: "med gratis personliggjorte indholdoplevelser",
      share_qr_code_description: "Download QR-koden eller tag et screenshot for hurtigere deling.",
      download_qr_code: "Download QR-kode",
      bilbert_email_start: "Jeg laver en e-mail kun til dig! Giv mig et øjeblik",
      bilbert_email_end: "Alt færdigt! Klik på knappen nedenfor for at kopiere teksten, og indsæt den derefter i en e-mail og send!",
      ai_robot: "AI Assistent",
      sign_up: "Tilmeld dig",
      already_signed_up: "Allerede tilmeldt?",
      cookies_description: "Vi bruger cookies for at forbedre din oplevelse.",
      learn_more: "Læs mere",
      accept: "Accepter",
      hooray: "Hurra!",
      experience_creation_description: "Giv os et øjeblik, mens vi skaber din personlige oplevelse...",
      share_link_everywhere: "Del dit link overalt",
      sales_links_header: "Links til deling med partnere",
      sales_links_description:
        "Deltager du i et møde eller event, holder et webinar eller sender en e-mail? Dette er alt hvad du behøver for at invitere partnere til at personliggøre og dele dine indholdoplevelser.",
      step_one_header: "Kopiér invitationlinket eller download QR-koden",
      step_one_description: "Kopiér invitationlinket eller download QR-koden for alle :partnerName indholdoplevelser, eller vælg en specifik indholdoplevelse.",
      step_one_tip: "Glem ikke at **teste** din QR-kode før du deler den!",
      copy_invite_link: "Kopiér invitationlink",
      copied_to_clipboard: "Kopieret til udklipsholderen",
      or_choose_content_experience: "Eller vælg en specifik indholdoplevelse:",
      step_two_header: "Sæt det ind i dit webinarpræsentation, e-mail og sociale medieindlæg",
      step_two_description: "Sæt det ind i din webinarpræsentation, e-mail, sociale medieindlæg... hvor du end engagerer dig med handelspartnere.",
      step_three_header: "Tilføj forklarende tekst",
      step_three_description: "Tilføj forklarende tekst for at hjælpe partnere med at forstå, hvorfor du deler links og QR-koder.",
      step_three_tip: "Glem ikke at indsætte dit link og/eller QR-kode!",
      sales_example_one: "Det er den nemmeste måde at sælge :partnerName. Klik for at komme i gang!",
      sales_example_two: "Klik for at straks personliggøre og dele vores nyeste indholdoplevelse.",
      sales_example_three: "Klik for at begynde at sælge :partnerName med det samme",
      just_a_moment: "Et øjeblik",
      thats_strange: "Det er mærkeligt...",
      invalid_magic_link: "Denne magiske link er ugyldig. Klik venligst på knappen for magisk link igen eller indsæt URL",
      oops: "Ups!",
      magic_link_used: "Magiske links er kun til engangsbrug, og denne er allerede blevet brugt. Indtast din e-mailadresse igen for at få et nyt magisk link.",
      get_new_magic_link: "Få dit nye magiske link!",
      new_magic_link_sent: "Dit magiske link er udløbet, så vi har sendt dig et nyt.",
      magic_link_check_email: "Tjek din e-mail og klik for at logge ind",
      cobrand_our_website: "Personalisér vores hjemmeside",
      required_field: "Dette felt er påkrævet.",
      sales_example_description: "Her er nogle eksempler for at komme i gang:",
      confirm: "Bekræft",
      your: "Dit",
      you_email_com: "du",
      upload_image: "Upload billede...",
      demo_phone_number: "+45 33 555 1212",
    },
    ja: {
      first_name: "名前",
      last_name: "苗字",
      phone_number: "電話番号",
      logo: "ロゴ",
      personal_photo: "個人写真",
      personal_message: "個人メッセージ",
      call_to_action_button: "リンク付きボタン",
      button_text: "ボタンテキスト",
      none: "なし",
      preset: "プリセット",
      custom: "カスタム",
      preview: "プレビュー",
      share: "シェア",
      personalize: "パーソナライズ",
      view: "表示",
      complete_your_profile: "プロフィールを完成させる",
      personal_details: "連絡先情報",
      customize_content_experience: "コンテンツ体験のカスタマイズ",
      share_on_whatsapp: "WhatsAppでシェア",
      language: "言語",
      ai_description: "AIアシスタントがあなたのために何かを書いています！ コピーして、貼り付けて、編集して、シェアしてください。",
      ai_regenerate: "別の方法を試す",
      copy_text_create_post: "Facebookにコピー＆ペースト",
      copy_link_to_content_experience: "コンテンツ体験へのリンクをコピー",
      email: "メール",
      qr_code: "QRコード",
      link: "リンク",
      my_experiences: "私の体験",
      my_profile: "私のプロフィール",
      about_my_business: "私のビジネスについて",
      i_am_a_travel_advisor: "私は旅行アドバイザー/旅行代理店または類似の第三者販売者です",
      host_agency: "ホストエージェンシー",
      agency_association_consortium: "エージェンシー協会/コンソーシアム",
      booking_email: "予約に使用したメール",
      booking_phone_number: "予約に使用した電話番号",
      iata_used_for_booking: "予約に使用したIATA",
      clia_used_for_booking: "予約に使用したCLIA",
      company_name: "会社名",
      business_languages: "業務で使用する言語",
      update: "更新",
      saving: "保存中",
      saved: "保存しました",
      logout: "ログアウト",
      cobrand_and_share: "パーソナライズ＆シェア",
      your_content_experiences: "あなたのコンテンツ体験",
      your_experiences: "あなたの体験",
      new_experiences: "新しい体験",
      all_content_experiences: "すべてのコンテンツ体験",
      search: "検索",
      experiences: "体験",
      cobranded_website: "パーソナライズウェブサイト",
      about: "会社情報",
      your_partnerName_content_experiences: ":partnerNameのコンテンツ体験",
      content_experiences_description:
        "コンテンツ体験を使うことで、インスピレーションを与えるコンテンツを非常にパーソナライズされた方法で素早くシェアできます。**下のコンテンツ体験をクリックして始めましょう。**",
      cobrand_the_partnerName_website: ":partnerNameのウェブサイトをパーソナライズ",
      cobrand_website_url_description:
        "こちらがあなたのパーソナライズされた:partnerNameウェブサイトです。このリンクをクライアントと共有してください - あなたのためにウェブサイトを変換しました。",
      copy_link: "リンクをコピー",
      copied: "コピーしました",
      cobrand_website_any_url_description:
        "さらに良くなりました！ :partnerNameのウェブサイトのどのページでもパーソナライズできます。 :partnerNameのURLを入力すれば、あなたのパーソナライズリンクを取得できます。",
      cobrand: "パーソナライズ",
      contact: "お問い合わせ",
      for_travel_brands: "旅行ブランド向け",
      recommend: "おすすめ",
      recommend_us_to_suppliers: "お気に入りのサプライヤーに推薦する",
      recommend_us_description:
        "すべてのサプライヤーがこんなに簡単にマーケティングできたらいいと思いませんか？サプライヤーに、どれだけ簡単にコンテンツを作成してあなたのマーケティングを強化できるかを教えてください。ボタンをクリックして、私たちがメールを作成します！",
      cancel: "キャンセル",
      copy_and_paste_in_email: "コピーして（メールに貼り付けて）",
      pagination: ":itemsFrom - :itemsTo of :itemsTotal items.",
      choose_a_content_experience: "コンテンツ体験を選んでください",
      sign_in: "サインイン",
      get_started: "開始",
      sign_in_to_account: "アカウントにサインイン",
      sign_in_description: "メールアドレスを入力すると、自動的にサインインできるリンクをお送りします。",
      email_address: "メールアドレス",
      no_account: "アカウントをお持ちでない場合",
      sign_up_title: "パーソナライズされたコンテンツを共有するためにサインアップ",
      sign_up_description: "数秒で、あなたのマーケティングを次のレベルに引き上げるために、コンテンツ体験をパーソナライズして共有できます。",
      create_account: "アカウントを作成",
      personalize_your_content_experience: "このコンテンツ体験をパーソナライズ",
      invitation_description:
        "私たちはあなたのマーケティングを強化し、より多くの収益を得るためにこのコンテンツ体験を作成しました。簡単にパーソナライズできます—メールを入力して始めましょう！",
      enter_email_legal:
        "メールアドレスを入力すると、[利用規約](https://approachguides.com/terms/)および[プライバシーポリシー](https://approachguides.com/privacy/)に同意したことになります。",
      and: "と",
      magic_link_email_sent_title: "メールを確認してください！",
      magic_link_email_sent_description: "私たちが送った魔法のリンクをクリックしてください。**:userEmail**",
      magic_link_legal:
        "魔法のリンクをクリックすると、[利用規約](https://approachguides.com/terms/)および[プライバシーポリシー](https://approachguides.com/privacy/)に同意したことになります。",
      preview_description: "ヒント：クライアントビューを見るためにプライベートブラウザを使用してください。",
      name: "名前",
      logo_personal_photo: "ロゴ＆個人写真",
      location: "場所",
      consortium: "コンソーシアム",
      booking_email_phone: "予約用メール＆電話番号",
      home: "ホーム",
      update_profile: "プロフィール更新",
      profile_instructions: "強調表示されたフィールドを確認または記入してください。",
      select: "選択",
      country: "国",
      state_region: "都道府県 / 地域",
      homepage_title: "マーケティングと販売を次のレベルに引き上げる",
      homepage_subtitle: "無料のパーソナライズされたコンテンツ体験で",
      share_qr_code_description: "QRコードをダウンロードするか、スクリーンショットを撮って迅速に共有します。",
      download_qr_code: "QRコードをダウンロード",
      bilbert_email_start: "あなたのためにメールを作成しています！少々お待ちください",
      bilbert_email_end: "完了しました！下のボタンをクリックしてテキストをコピーし、それをメールに貼り付けて送信してください！",
      ai_robot: "AIアシスタント",
      sign_up: "サインアップ",
      already_signed_up: "すでに登録済み？",
      cookies_description: "私たちはあなたの体験を改善するためにクッキーを使用しています。",
      learn_more: "詳細情報",
      accept: "同意する",
      hooray: "やった！",
      experience_creation_description: "個別の体験を作成するために少々お待ちください...",
      share_link_everywhere: "どこでもリンクをシェア",
      sales_links_header: "パートナーと共有するリンク",
      sales_links_description:
        "会議やイベントに参加して、ウェビナーを開催し、メールを送信していますか？これはパートナーにコンテンツ体験をカスタマイズして共有するために必要なすべてです。",
      step_one_header: "招待リンクをコピーするか、QRコードをダウンロードする",
      step_one_description: "すべての:partnerNameコンテンツ体験、または特定の体験を選択して、招待リンクをコピーするかQRコードをダウンロードしてください。",
      step_one_tip: "QRコードを共有する前に必ず**テスト**してください！",
      copy_invite_link: "招待リンクをコピー",
      copied_to_clipboard: "クリップボードにコピーしました",
      or_choose_content_experience: "または特定のコンテンツ体験を選択してください：",
      step_two_header: "ウェビナーのプレゼンテーション、メール、ソーシャルメディア投稿に入れる",
      step_two_description: "ウェビナーのプレゼンテーション、メール、ソーシャルメディア投稿に入れて...パートナーとのやり取り場所に入れてください。",
      step_three_header: "説明テキストを追加",
      step_three_description: "リンクやQRコードを共有している理由をパートナーが理解できるように説明テキストを追加します。",
      step_three_tip: "リンクと/またはQRコードを忘れずに貼り付けてください！",
      sales_example_one: ":partnerNameを売る最も簡単な方法です。クリックして始めましょう！",
      sales_example_two: "クリックして、私たちの最新のコンテンツ体験を即座にパーソナライズして共有。",
      sales_example_three: "クリックして、即座に:partnerNameを販売し始めます",
      just_a_moment: "少々お待ちください",
      thats_strange: "それは変ですね...",
      invalid_magic_link: "この魔法のリンクは無効です。魔法のリンクボタンを再度クリックするか、関連するURLを貼り付けてください",
      oops: "おっと！",
      magic_link_used: "魔法のリンクは一度限りの使用のため、すでに使用されています。新しい魔法のリンクを受け取るために再度メールアドレスを入力してください。",
      get_new_magic_link: "新しい魔法のリンクを取得！",
      new_magic_link_sent: "魔法のリンクが期限切れになったので、新しいリンクを送信しました。",
      magic_link_check_email: "メールを確認して、ログインするためにクリックしてください",
      cobrand_our_website: "私たちのウェブサイトをパーソナライズ",
      required_field: "このフィールドは必須です。",
      sales_example_description: "始めるためのいくつかの例をご紹介します：",
      confirm: "確認",
      your: "あなたの",
      you_email_com: "anata",
      upload_image: "画像をアップロード...",
      demo_phone_number: "+81 3 5551 1212",
    },
    no: {
      first_name: "Fornavn",
      last_name: "Etternavn",
      phone_number: "Telefonnummer",
      logo: "Logo",
      personal_photo: "Personlig bilde",
      personal_message: "Personlig melding",
      call_to_action_button: "Knapp med lenke",
      button_text: "Knapptekst",
      none: "Ingen",
      preset: "Forhåndsinnstilling",
      custom: "Egendefinert",
      preview: "Forhåndsvisning",
      share: "Del",
      personalize: "Personliggjør",
      view: "Vis",
      complete_your_profile: "Fullfør profilen din",
      personal_details: "Kontaktinformasjon",
      customize_content_experience: "Tilpass innholdsopplevelse",
      share_on_whatsapp: "Del på WhatsApp",
      language: "Språk",
      ai_description: "Vår AI-assistent skriver noe spesielt for deg! Bare kopier, lim inn, rediger og del.",
      ai_regenerate: "Prøv noe annet",
      copy_text_create_post: "Kopier og lim inn på Facebook",
      copy_link_to_content_experience: "Kopier lenke til innholdsopplevelsen din",
      email: "E-post",
      qr_code: "QR-kode",
      link: "Lenke",
      my_experiences: "Mine opplevelser",
      my_profile: "Min profil",
      about_my_business: "Om min bedrift",
      i_am_a_travel_advisor: "Jeg er en reiseekspert/reisebyrå eller en lignende tredjepartsreisemegler",
      host_agency: "Vertbyrå",
      agency_association_consortium: "Byråforening / Konsortium",
      booking_email: "E-post brukt til booking",
      booking_phone_number: "Telefonnummer brukt til booking",
      iata_used_for_booking: "IATA brukt til booking",
      clia_used_for_booking: "CLIA brukt til booking",
      company_name: "Firmanavn",
      business_languages: "Språk jeg driver forretning på",
      update: "Oppdater",
      saving: "Lagrer",
      saved: "Lagret",
      logout: "Logg ut",
      cobrand_and_share: "Personliggjør og del",
      your_content_experiences: "Dine innholdsopplevelser",
      your_experiences: "Dine opplevelser",
      new_experiences: "Nye opplevelser",
      all_content_experiences: "Alle innholdsopplevelser",
      search: "Søk",
      experiences: "Opplevelser",
      cobranded_website: "Personliggjort nettside",
      about: "Om",
      your_partnerName_content_experiences: "Dine :partnerName innholdsopplevelser",
      content_experiences_description:
        "Innholdsopplevelser gjør at du kan dele inspirerende innhold på en svært personlig måte. **Klikk på en innholdsopplevelse nedenfor for å komme i gang.**",
      cobrand_the_partnerName_website: "Personliggjør :partnerName nettside",
      cobrand_website_url_description: "Her er din personlige :partnerName nettside. Del denne lenken med kunder – vi har omgjort nettsiden kun for deg.",
      copy_link: "Kopier lenke",
      copied: "Kopiert",
      cobrand_website_any_url_description:
        "Det blir enda bedre! Du kan personliggjøre hvilken som helst side på :partnerName nettside. Skriv bare inn hvilken som helst :partnerName URL for å få din personlige lenke.",
      cobrand: "Personliggjør",
      contact: "Kontakt",
      for_travel_brands: "For reisebedrifter",
      recommend: "Anbefal",
      recommend_us_to_suppliers: "Anbefal oss til dine favorittleverandører",
      recommend_us_description:
        "Skulle du ønske at alle leverandørene dine gjorde markedsføring så enkelt? La dem få vite at de kan lage innhold som hever markedsføringen din. Klikk på knappen, og la oss lage en e-post for deg!",
      cancel: "Avbryt",
      copy_and_paste_in_email: "Kopier (og lim inn i en e-post)",
      pagination: ":itemsFrom - :itemsTo av :itemsTotal artikler.",
      choose_a_content_experience: "Velg en innholdsopplevelse",
      sign_in: "Logg inn",
      get_started: "Kom i gang",
      sign_in_to_account: "Logg inn på kontoen din",
      sign_in_description: "Skriv inn e-posten din, og vi sender deg en lenke som logger deg inn automatisk.",
      email_address: "E-postadresse",
      no_account: "Har du ikke en konto?",
      sign_up_title: "Registrer deg for å begynne å dele tilpasset innhold",
      sign_up_description: "På få sekunder kan du tilpasse og dele våre innholdsopplevelser for å heve markedsføringen din.",
      create_account: "Opprett konto",
      personalize_your_content_experience: "Personliggjør denne innholdsopplevelsen",
      invitation_description:
        "Vi har laget denne innholdsopplevelsen for å heve markedsføringen din og hjelpe deg med å tjene mer. Det er enkelt å tilpasse – bare skriv inn e-posten din for å komme i gang!",
      enter_email_legal:
        "Ved å skrive inn e-postadressen din godtar du våre [vilkår for bruk](https://approachguides.com/terms/) og [personvernerklæring](https://approachguides.com/privacy/)",
      and: "og",
      magic_link_email_sent_title: "Sjekk e-posten din!",
      magic_link_email_sent_description: "Klikk på den magiske lenken vi sendte til **:userEmail**",
      magic_link_legal:
        "Ved å klikke på den magiske lenken godtar du våre [vilkår for bruk](https://approachguides.com/terms/) og [personvernerklæring](https://approachguides.com/privacy/)",
      preview_description: "Tips: Bruk et inkognitovindu for å se hva kundene dine ser.",
      name: "Navn",
      logo_personal_photo: "Logo & Personlig foto",
      location: "Sted",
      consortium: "Konsortium",
      booking_email_phone: "Booking-e-post & telefon",
      home: "Hjem",
      update_profile: "Oppdater profil",
      profile_instructions: "Vennligst bekreft eller fyll ut de uthevede feltene.",
      select: "Velg",
      country: "Land",
      state_region: "Stat / Region",
      homepage_title: "Ta markedsføringen og salget ditt til neste nivå",
      homepage_subtitle: "med gratis tilpassede innholdsopplevelser",
      share_qr_code_description: "Last ned QR-koden eller ta et skjermbilde for raskere deling.",
      download_qr_code: "Last ned QR-kode",
      bilbert_email_start: "Jeg lager en e-post bare for deg! Gi meg et øyeblikk",
      bilbert_email_end: "Alt er klart! Klikk på knappen nedenfor for å kopiere teksten, og lim den deretter inn i en e-post og send!",
      ai_robot: "AI Assistent",
      sign_up: "Registrer deg",
      already_signed_up: "Allerede registrert?",
      cookies_description: "Vi bruker informasjonskapsler for å forbedre din opplevelse.",
      learn_more: "Lær mer",
      accept: "Godta",
      hooray: "Hurra!",
      experience_creation_description: "Gi oss et øyeblikk mens vi lager din tilpassede opplevelse...",
      share_link_everywhere: "Del lenken din overalt",
      sales_links_header: "Lenker å dele med partnere",
      sales_links_description:
        "Deltar på et møte eller arrangement, holder et webinar, eller sender ut en e-post? Dette er alt du trenger for å invitere partnere til å tilpasse og dele innholdet ditt.",
      step_one_header: "Kopier invitasjonslenken eller last ned QR-koden",
      step_one_description:
        "Kopier invitasjonslenken eller last ned QR-koden for alle :partnerName innholdsopplevelser, eller velg en spesifikk innholdsopplevelse.",
      step_one_tip: "Husk å **teste** QR-koden før du deler!",
      copy_invite_link: "Kopier invitasjonslenke",
      copied_to_clipboard: "Kopiert til utklippstavle",
      or_choose_content_experience: "Eller velg en spesifikk innholdsopplevelse:",
      step_two_header: "Legg det til i webinarpresentasjonen, e-posten og innleggene på sosiale medier",
      step_two_description: "Legg det til i webinarpresentasjonen, e-posten, sosiale medieinnlegg... uansett hvor du engasjerer deg med partnere.",
      step_three_header: "Legg til forklarende tekst",
      step_three_description: "Legg til forklarende tekst for å hjelpe partnerne med å forstå hvorfor du deler lenker og QR-koder.",
      step_three_tip: "Husk å lime inn lenken og/eller QR-koden!",
      sales_example_one: "Det er den enkleste måten å selge :partnerName. Klikk for å komme i gang!",
      sales_example_two: "Klikk for å umiddelbart personalisere og dele vår nyeste innholdserfaring.",
      sales_example_three: "Klikk for å begynne å selge :partnerName umiddelbart",
      just_a_moment: "Et øyeblikk",
      thats_strange: "Det er rart...",
      invalid_magic_link: "Denne magiske lenken er ugyldig. Vennligst klikk på den magiske lenken igjen eller lim inn URL-en",
      oops: "Oops!",
      magic_link_used:
        "Magiske lenker er for én gangs bruk, og denne har allerede blitt brukt. Vennligst skriv inn e-postadressen din på nytt for å motta en ny magisk lenke.",
      get_new_magic_link: "Få din nye magiske lenke!",
      new_magic_link_sent: "Din magiske lenke har utløpt, så vi har sendt deg en ny.",
      magic_link_check_email: "Sjekk e-posten din og klikk for å logge inn",
      cobrand_our_website: "Personalisere nettstedet vårt",
      required_field: "Dette feltet er påkrevd.",
      sales_example_description: "Her er noen eksempler for å komme i gang:",
      confirm: "Bekreft",
      your: "Ditt",
      you_email_com: "du",
      upload_image: "Last opp bilde...",
      demo_phone_number: "+47 22 555 1212",
    },
  },
}));
